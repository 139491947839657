import React from "react";
import { Image, Pressable, ScrollView, View } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/general/cards/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Button } from "../../components/input";
import { Unauthorized } from "./unauthorized";
import { useAuthContext } from "../../contexts/auth-context";
import { useBookingsContext } from "../../contexts/bookings-context";
import axiosInstance from "../../constants/axios-config";
import { apiURIV2, mediaURI } from "../../constants";
import { isSignedIn } from "../../actions/auth-actions";
import { cloneDeep } from "lodash";
import { RefreshControl } from "react-native";
import { FlatList } from "react-native";
import ResponseModal from "../../components/general/modals/ResponseModal";
import ServiceCard from "../../components/general/cards/service-card";
import ListingPlaceholder from "../../components/general/placeholders/listing-placeholder";
import BarcodeScanner from "../../components/qrcodescanner";
import { BarCodeScanner } from "expo-barcode-scanner";
import { Platform } from "react-native";
import { getFullDateTime } from "../../utils/date-time-util";
import { bookingStatusBadgeData } from "../../utils/statusBadgeValues";
import { useMessagesContext } from "../../contexts/messages-context";
import { catchAPIErrorText } from "../../utils/error-handler";

export default function BookingDetails({ navigation, route }) {
  const { user } = useAuthContext();
  const { setRedirectChatUserID } = useMessagesContext();
  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });
  const [bDIsLoading, setBDIsLoading] = React.useState();
  const [bookingDetails, setBookingDetails] = React.useState();
  const [isBarcodeScannerVisible, setIsBarcodeScannerVisible] = React.useState({
    visible: false,
    onRequestClose: () =>
      setIsBarcodeScannerVisible((data) => {
        data.visible = false;
        return { ...data };
      }),
  });
  const [verifyingQRCode, setVerifyingQRCode] = React.useState(false);

  const {
    bookings,
    setBookings,
    // bookingDetailsData,
    // setBookingDetailsData,
  } = useBookingsContext();

  const bid = route.params?.bid;

  // const [booking, setBooking] = React.useState({
  //   booking: {
  //     id: "",
  //     status: "",
  //     start: "",
  //     end: "",
  //     guest: "",
  //     qr: "",
  //   },
  //   customer: {
  //     full_name: "John Doe",
  //     email: "customer@smbex.com",
  //     phone: "9876543210",
  //     address: "Unknown City",
  //   },
  //   services: [
  //     { name: "asd", quantity: 1, price: 1, subtotal: 1 },
  //     { name: "asdasdasd ", quantity: 1, price: 1, subtotal: 1 },
  //   ],
  //   payment: {
  //     method: "UPI",
  //     total: "$ 100",
  //     discount: "50%",
  //     by_reward: "SMBEX50",
  //     payment: "$ 50",
  //   },
  //   rating: {
  //     stars: 0,
  //     review: "",
  //   },
  // });

  if (!bid) {
    return navigation.replace("bookings");
  }

  const getBookingDetails = async () => {
    setBDIsLoading(true);
    let data;
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.get(
        apiURIV2 + "/bookings/service/" + bid,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        data = res.data.data;
      }
    } catch (e) {
      console.log("e.res", e?.response, e);
      if ([404].includes(e?.response?.status)) {
        return navigation.replace("bookings");
      }
    } finally {
      if (data) {
        const parseTime = (parseObj) => {
          const getMinutes = (time_in_min) => {
            let m = time_in_min % 60;
            if (m < 10) {
              m = "0" + m;
            }
            return m.toString();
          };
          const getHours = (time_in_min) => {
            let h = parseInt(time_in_min / 60);
            if (h > 12) {
              h -= 12;
            }
            if (h < 10) {
              h = "0" + h;
            }
            return h.toString();
          };
          parseObj?.sessions?.map((v) => {
            let startInHour = getHours(v.start_in_min);
            let startInMin = getMinutes(v.start_in_min);
            v.start_time = `${startInHour}:${startInMin} ${
              startInHour < 13 ? "AM" : "PM"
            }`;
            let endInHour = getHours(v.end_in_min);
            let endInMin = getMinutes(v.end_in_min);
            v.end_time = `${endInHour}:${endInMin} ${
              endInHour < 13 ? "AM" : "PM"
            }`;
            return v;
          });
          return parseObj;
        };
        // setBookingDetailsData({ data: parseTime(data), id: bid });
        setBookingDetails(parseTime(data));
      }
      setBDIsLoading(false);
    }
  };

  if (!bookingDetails && !bDIsLoading) {
    getBookingDetails();
  }

  if (!bookingDetails) {
    return (
      <View className="flex-1 justify-center items-center">
        {showResponseModal.visible && (
          <ResponseModal
            visible={showResponseModal.visible}
            onRequestClose={showResponseModal.onRequestClose}
            bodyText={showResponseModal.bodyText}
          />
        )}
        <Font>Loading...</Font>
      </View>
    );
  }

  const approveBooking = async () => {
    setBDIsLoading(true);
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.post(
        apiURIV2 + "/bookings/service/" + bid + "/confirm",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        console.log("res.data.message", res?.data?.message);
        let message = res.data?.message;
        if (message) {
          setShowResponseModal({
            visible: true,
            onRequestClose: () => {
              setShowResponseModal({
                visible: false,
                onRequestClose: () => null,
                bodyText: "",
              });
            },
            bodyText: message,
          });
        }
        setBookingDetails((data) => {
          data.status = "confirmed";
          return data;
        });
        if (bookings) {
          const updatedData = cloneDeep(bookings);
          const i = updatedData?.findIndex((v) => v.id == bid);
          updatedData[i].status = "confirmed";
          setBookings({ data: updatedData });
        }
      }
    } catch (e) {
      console.log("e.res", e?.response, e);
      console.log("error message:", bodyText);
      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error while Getting Bookings"),
      });
    } finally {
      setBDIsLoading(false);
    }
  };

  const rejectBooking = async () => {
    setBDIsLoading(true);
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.post(
        apiURIV2 + "/bookings/service/" + bid + "/reject",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        console.log("res.data.message", res?.data?.message);
        let message = res.data?.message;
        if (message) {
          setShowResponseModal({
            visible: true,
            onRequestClose: () => {
              setShowResponseModal({
                visible: false,
                onRequestClose: () => null,
                bodyText: "",
              });
            },
            bodyText: message,
          });
        }
        setBookingDetails((data) => {
          data.status = "cancelled_by_vendor";
          return data;
        });
        if (bookings) {
          const updatedData = cloneDeep(bookings);
          const i = updatedData?.findIndex((v) => v.id == bid);
          updatedData[i].status = "cancelled_by_vendor";
          setBookings({ data: updatedData });
        }
      }
    } catch (e) {
      console.log("e.res", e?.response, e);
      console.log("error message:", bodyText);
      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error while Getting Bookings"),
      });
    } finally {
      setBDIsLoading(false);
    }
  };

  const completeBooking = async ({ data }) => {
    setVerifyingQRCode(true);
    const token = await isSignedIn();
    axiosInstance
      .post(
        apiURIV2 + "/bookings/service/" + bid + "/complete",
        { verification_token: data },
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          // setShowBookableServices(data);
          console.log("res.data:", res.data);
          setBookingDetails((data) => {
            data = cloneDeep(data);
            data.status = "completed";
            return data;
          });
          if (bookings) {
            const updatedData = cloneDeep(bookings);
            const i = updatedData?.findIndex((v) => v.id == bid);
            updatedData[i].status = "completed";
            setBookings({ data: updatedData });
          }
          return res;
        }
      })
      .catch((e) => {
        console.log("e.res", e?.response, e);
        return e?.response;
      })
      .then((res) => {
        if (res) {
          console.log("response data:", res.data);
          setShowResponseModal({
            visible: true,
            onRequestClose: () => {
              setShowResponseModal({
                visible: false,
                onRequestClose: () => null,
                bodyText: "",
              });
            },
            bodyText:
              res?.data?.message || `${res?.status}: ${res?.statusText}`,
          });
        }
      })
      .finally(() => {
        setVerifyingQRCode(false);
        isBarcodeScannerVisible.onRequestClose();
      });
  };

  let status = bookingDetails.status,
    bookingStatus = bookingStatusBadgeData(status);

  return user ? (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      {isBarcodeScannerVisible.visible && (
        <BarcodeScanner
          barCodeTypes={[BarCodeScanner.Constants.BarCodeType.qr]}
          visible={isBarcodeScannerVisible.visible}
          showLoadingIndicator={verifyingQRCode}
          onRequestClose={() => {
            // setVerifyingQRCode(false);
            isBarcodeScannerVisible.onRequestClose();
          }}
          onScannedBarcodeInfo={async ({ data }) => {
            setVerifyingQRCode(true);
            const token = await isSignedIn();
            axiosInstance
              .post(
                apiURIV2 + "/bookings/service/" + bid + "/verify",
                { verification_token: data },
                {
                  headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => {
                if (res.status === 200) {
                  // setShowBookableServices(data);
                  console.log("res.data:", res.data);
                  console.log(
                    "res?.data?.data:",
                    res?.data?.message,
                    res?.data?.data
                  );
                  setBookingDetails((data) => {
                    data = cloneDeep(data);
                    data.status = "verified";
                    return data;
                  });
                  if (bookings) {
                    const updatedData = cloneDeep(bookings);
                    const i = updatedData?.findIndex((v) => v.id == bid);
                    updatedData[i].status = "verified";
                    setBookings({ data: updatedData });
                  }
                  return res;
                }
              })
              .catch((e) => {
                console.log("e.res", e?.response, e);
                return e?.response;
              })
              .then((res) => {
                if (res) {
                  console.log("response data:", res.data);
                  setShowResponseModal({
                    visible: true,
                    onRequestClose: () => {
                      setShowResponseModal({
                        visible: false,
                        onRequestClose: () => null,
                        bodyText: "",
                      });
                    },
                    bodyText:
                      res?.data?.message ||
                      `${res?.status}: ${res?.statusText}`,
                  });
                }
              })
              .finally(() => {
                setVerifyingQRCode(false);
                isBarcodeScannerVisible.onRequestClose();
              });
          }}
        />
      )}
      {showResponseModal.visible && (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
        />
      )}
      <View className="rounded-t-2xl lg:rounded-none overflow-hidden flex-1 bg-gray-50">
        <ScrollView
          className="rounded-t-2xl p-4"
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
          refreshControl={
            Platform.OS === "web" ? null : (
              <RefreshControl
                refreshing={bDIsLoading}
                onRefresh={() => {
                  getBookingDetails();
                }}
              />
            )
          }
        >
          <View className="flex-1 h-full w-full lg:px-4 items-center">
            <View className="my-5 hidden lg:flex flex-row w-full max-w-screen-lg">
              <Font className="text-lg font-semibold text-app-d flex-1">
                Booking Details
              </Font>
            </View>
            <View className="flex-col lg:flex-row lg:max-w-screen-lg w-full">
              <View className=" w-full lg:w-4/6 p-0 lg:pr-4 ">
                <Card
                  title="Booking Info"
                  Widget={() => (
                    <View className="-mt-1 -mb-2 flex-row h-max">
                      {["confirmed"].includes(bookingDetails?.status) ? (
                        <Pressable
                          className="px-2 -mt-1 self-center"
                          onPress={() =>
                            setIsBarcodeScannerVisible((data) => {
                              data.visible = true;
                              return { ...data };
                            })
                          }
                        >
                          <RemixIcon name="ri-qr-scan-2-line" />
                        </Pressable>
                      ) : null}
                      <Font
                        className={`uppercase self-start -mb-4 rounded-full px-2 py-1 text-xs border ${bookingStatus.bgcolor} ${bookingStatus.borderColor} ${bookingStatus.textColor}`}
                      >
                        {bookingStatus?.text}
                      </Font>
                    </View>
                  )}
                >
                  <View className="w-full lg:flex-row flex-wrap justify-around px-4 pb-4">
                    {/* <View className="items-center w-full md:w-1/2 mb-4">
                      {bookingDetails.qr_url ? (
                        <Image
                          source={{
                            uri: mediaURI + bookingDetails.qr_url,
                            // : require("../../../assets/qr.png"),
                          }}
                          className="w-60 h-60 lg:w-48 lg:h-48 xl:w-60 xl:h-60"
                        ></Image>
                      ) : (
                        <View className="w-60 h-60 justify-center items-center border border-app-c rounded-lg">
                          <Font>No QR Code</Font>
                        </View>
                      )}
                    </View> */}
                    {/* <View className="w-full"> */}
                    <View className="mb-2">
                      <Font className="text-app-d" weight={600}>
                        Booking ID:
                      </Font>
                      <Font className="text-app-d1" weight={500}>
                        # {bookingDetails?.id}
                      </Font>
                    </View>
                    <View className="mb-2">
                      <Font className="text-app-d" weight={600}>
                        Booked on:
                      </Font>
                      <Font className="text-app-d1" weight={500}>
                        {getFullDateTime(bookingDetails?.created_at)}
                      </Font>
                    </View>
                    <View className="mb-2">
                      <Font className="text-app-d" weight={600}>
                        Last Updated on:
                      </Font>
                      <Font className="text-app-d1" weight={500}>
                        {getFullDateTime(bookingDetails?.updated_at)}
                      </Font>
                    </View>
                    {/* </View> */}
                  </View>
                </Card>
                <Card title="Booked Service">
                  <View className="w-full px-4">
                    {bookingDetails?.service ? (
                      <View className="w-full py-4 pt-0 md:flex-row">
                        <ServiceCard
                          title={
                            bookingDetails?.service
                              ? bookingDetails?.service?.title ||
                                "Unknown Title"
                              : "Title Unavailable"
                          }
                          subtitle={
                            bookingDetails?.service
                              ? bookingDetails?.service?.subtitle ||
                                "No Description"
                              : "Description Unavailable"
                          }
                          price={
                            bookingDetails?.service
                              ? `${
                                  bookingDetails?.service?.service_unit
                                    ?.currency_symbol
                                } ${
                                  bookingDetails?.service?.service_unit?.price
                                    ? bookingDetails?.service?.service_unit
                                        ?.price / 100
                                    : "-"
                                } / ${
                                  bookingDetails?.service?.service_unit?.name ||
                                  "-"
                                }`
                              : "Unavailable"
                          }
                          special_price={
                            bookingDetails?.service
                              ? `${
                                  bookingDetails?.service?.service_unit
                                    ?.currency_symbol
                                } ${
                                  bookingDetails?.service?.service_unit
                                    ?.special_price
                                    ? bookingDetails?.service?.service_unit
                                        ?.special_price / 100
                                    : "-"
                                } / ${
                                  bookingDetails?.service?.service_unit?.name ||
                                  "-"
                                }`
                              : "Unavailable"
                          }
                          weekend_price={
                            bookingDetails?.service
                              ? `${
                                  bookingDetails?.service?.service_unit
                                    ?.currency_symbol
                                } ${
                                  bookingDetails?.service?.service_unit
                                    ?.weekend_price
                                    ? bookingDetails?.service?.service_unit
                                        ?.weekend_price / 100
                                    : "-"
                                } / ${
                                  bookingDetails?.service?.service_unit?.name ||
                                  "-"
                                }`
                              : "Unavailable"
                          }
                        />
                      </View>
                    ) : (
                      <View className="w-full justify-center items-center px-4 pb-4">
                        <Font>Service Details Unavailable</Font>
                      </View>
                    )}
                  </View>
                </Card>
                <Card title="Booked Sessions">
                  <FlatList
                    className="w-full px-4"
                    horizontal={!!bookingDetails.sessions}
                    ItemSeparatorComponent={() => (
                      <View style={{ marginHorizontal: 10 }}></View>
                    )}
                    ListEmptyComponent={() => (
                      <View className="w-full justify-center items-center pb-4">
                        <Font>Sessions Unavailable</Font>
                      </View>
                    )}
                    data={bookingDetails?.sessions}
                    renderItem={({
                      item: {
                        id,
                        service_unit_quantity,
                        date,
                        start_time,
                        end_time,
                      } = {},
                      index: i,
                    }) => (
                      <View
                        className="w-64 border border-app-d3 rounded-lg px-4 py-2 mb-4"
                        key={"slot" + id}
                      >
                        <View className="w-full border-b border-app-c py-2">
                          <Font className="text-app-d">{`Session ${
                            i + 1
                          }`}</Font>
                        </View>
                        <View className="py-2 w-full flex-row flex-wrap">
                          <View className="w-full mb-2">
                            <Font className="text-app-d" weight={600}>
                              Dated:
                            </Font>
                            <Font className="text-app-d1" weight={500}>
                              {date || "-"}
                            </Font>
                          </View>
                          <View className="w-1/2 mb-2">
                            <Font className="text-app-d" weight={600}>
                              Start Time:
                            </Font>
                            <Font className="text-app-d1" weight={500}>
                              {start_time || "-"}
                            </Font>
                          </View>
                          <View className="w-1/2 mb-2">
                            <Font className="text-app-d" weight={600}>
                              End Time:
                            </Font>
                            <Font className="text-app-d1" weight={500}>
                              {end_time || "-"}
                            </Font>
                          </View>
                          <View className="w-full">
                            <Font className="text-app-d" weight={600}>
                              Booking Unit(s):
                            </Font>
                            <Font className="text-app-d1" weight={500}>
                              {service_unit_quantity || "-"}
                            </Font>
                          </View>
                        </View>
                      </View>
                    )}
                  />
                </Card>
              </View>
              <View className=" w-full lg:w-2/6 p-0 lg:pl-4">
                <Card title="Booking Payment">
                  <View className="w-full">
                    <View className="w-full flex-row flex-wrap p-4 pt-0">
                      <View className="w-full flex-row justify-between mb-2">
                        <Font className="text-app-d mr-1" weight={600}>
                          Payment Method:
                        </Font>
                        <Font className="text-app-d1" weight={500}>
                          {["created"].includes(bookingDetails.status)
                            ? "- (Payment is still pending...)"
                            : "-"}
                        </Font>
                      </View>
                      <View className="w-full flex-row justify-between mb-2">
                        <Font className="text-app-d mr-1" weight={600}>
                          Total Price:
                        </Font>
                        <Font className="text-app-d1" weight={500}>
                          {`${bookingDetails?.currency_symbol} ${
                            bookingDetails?.total_amount
                              ? bookingDetails?.total_amount / 100
                              : bookingDetails?.total_amount ?? "-"
                          }`}
                        </Font>
                      </View>
                      <View className="w-full flex-row justify-between mb-2">
                        <Font className="text-app-d mr-1" weight={600}>
                          Applied coupon:
                        </Font>
                        <Font className="text-app-d1 text-base" weight={600}>
                          {bookingDetails?.applied_coupon?.code || "-"}
                        </Font>
                      </View>
                      <View className="w-full flex-row justify-between mb-2">
                        <Font className="text-app-d mr-1" weight={600}>
                          Coupon amount:
                        </Font>
                        <Font className="text-app-g" weight={500}>
                          - $ { +(bookingDetails?.applied_coupon?.discount_amount || 0) / 100}
                        </Font>
                      </View>
                      <View className="w-full flex-row justify-between mb-2">
                        <Font className="text-app-d mr-1" weight={600}>
                        Wallet Amount:
                        </Font>
                        <Font className="text-app-g" weight={500}>
                          {bookingDetails?.redeemed_rewards
                            ?.discount_amount
                            ? ("- " +
                                bookingDetails?.currency_symbol ||
                                "") +
                              " " +
                              bookingDetails?.redeemed_rewards
                                ?.discount_amount /
                                100
                            : bookingDetails?.redeemed_rewards
                                ?.discount_amount ?? "-"}
                        </Font>
                      </View>
                      {/* <View className="w-full flex-row justify-between mb-2">
                        <Font className="text-app-d mr-1" weight={600}>
                          Wallet Amount:
                        </Font>
                        <Font className="text-app-d1" weight={500}>
                          -
                        </Font>
                      </View> */}
                      <View className="w-full flex-row justify-between mb-2 ">
                        <Font className="text-app-d text-base" weight={600}>
                          Total Payment
                        </Font>
                        <Font className="text-app-d1 text-base" weight={600}>
                          {`${bookingDetails?.currency_symbol} ${
                            bookingDetails?.net_payable / 100
                              
                          }`}
                        </Font>
                      </View>
                    </View>
                  </View>
                </Card>
                <Card title="Customer">
                  <View className="w-full px-4">
                    <View className="w-full flex-row flex-wrap pt-0 pb-2">
                      <View className="w-1/2 lg:w-full mb-2">
                        <Font className="text-app-d mr-1" weight={600}>
                          Full name:
                        </Font>
                        <Font className="text-app-d1" weight={500}>
                          {`${
                            bookingDetails?.user?.first_name
                              ? bookingDetails?.user?.first_name
                              : "-"
                          }${
                            bookingDetails?.user?.last_name
                              ? " " + bookingDetails?.user?.last_name
                              : ""
                          }`}
                        </Font>
                      </View>
                      <View className="w-1/2 lg:w-full mb-2">
                        <Font className="text-app-d mr-1" weight={600}>
                          Email:
                        </Font>
                        <Font className="text-app-d1" weight={500}>
                          {bookingDetails?.user?.email || "-"}
                        </Font>
                      </View>
                      <View className="w-1/2 lg:w-full mb-2">
                        <Font className="text-app-d mr-1" weight={600}>
                          Phone:
                        </Font>
                        <Font className="text-app-d1" weight={500}>
                          {bookingDetails?.user?.phone || "-"}
                        </Font>
                      </View>
                      {!!bookingDetails?.user?.company_name && (
                        <View className="w-1/2 lg:w-full mb-2">
                          <Font className="text-app-d mr-1" weight={600}>
                            {`Company Name:${
                              bookingDetails?.user?.is_non_profit
                                ? " (Non-Profit)"
                                : ""
                            }`}
                          </Font>
                          <Font className="text-app-d1" weight={500}>
                            {bookingDetails?.user?.company_name || "-"}
                          </Font>
                        </View>
                      )}
                    </View>
                    <View className="mb-4">
                      <Button
                        leftIcon={{
                          name: "message-2-fill",
                          color: colors.app.d,
                        }}
                        label="Contact customer"
                        onPress={() => {
                          setRedirectChatUserID({
                            uid:
                              bookingDetails?.user_id ||
                              bookingDetails?.user?.id,
                            callback: () => navigation.navigate("messages"),
                          });
                        }}
                      ></Button>
                    </View>
                  </View>
                </Card>
                <Card title="Listing">
                  <View className="w-full px-4">
                    <Pressable
                      className="w-full bg-app-a rounded-lg p-0 border border-gray-200  mb-4"
                      onPress={() =>
                        navigation.navigate("update-listing", {
                          lid: bookingDetails?.listing?.id,
                        })
                      }
                    >
                      <View className="overflow-hidden rounded-none rounded-t-lg">
                        {bookingDetails?.listing?.cover_image_url ? (
                          <Image
                            source={
                              bookingDetails?.listing?.cover_image_url
                                ? {
                                    uri:
                                      mediaURI +
                                      bookingDetails?.listing?.cover_image_url,
                                  }
                                : require("../../../assets/bg.jpg")
                            }
                            className="w-full h-32 "
                          ></Image>
                        ) : (
                          <ListingPlaceholder
                            name={bookingDetails?.listing?.title || "-"}
                            className="w-full h-32"
                          />
                        )}
                      </View>
                      <View className="justify-center px-4 py-2">
                        <View
                          className="flex-row items-center"
                          style={{
                            marginVertical: 2,
                          }}
                        >
                          <Font
                            className="text-xs lg:text-md text-app-f"
                            weight={500}
                            numberOfLines={1}
                          >
                            {bookingDetails?.listing?.listing_category?.title ||
                              "-"}
                          </Font>
                        </View>
                        <Font
                          className="text-app-d leading- "
                          numberOfLines={2}
                          weight={500}
                        >
                          {bookingDetails?.listing?.title || "-"}
                        </Font>
                        <View
                          className="flex-row items-center mb-1 lg:mb-2 "
                          style={{
                            marginVertical: 2,
                          }}
                        >
                          <Font
                            className="  text-app-d1"
                            numberOfLines={1}
                            weight={500}
                          >
                            {`${
                              bookingDetails?.listing?.city
                                ? bookingDetails?.listing?.city
                                : "Unknown City"
                            }${
                              bookingDetails?.listing?.state
                                ? ", " + bookingDetails?.listing?.state
                                : bookingDetails?.listing?.country
                                ? ", " + bookingDetails?.listing?.country
                                : bookingDetails?.listing?.city
                                ? ", Unknown State/ Country"
                                : ""
                            }`}
                          </Font>
                        </View>
                      </View>
                    </Pressable>
                  </View>
                </Card>
                {["created", "pending_confirmation"].includes(
                  bookingDetails?.status
                ) && (
                  <View className="flex-row w-full mb-4">
                    <View className="flex-1 pr-2">
                      <Button
                        type="regular"
                        label="Reject"
                        onPress={rejectBooking}
                      ></Button>
                    </View>
                    <View className="flex-1 pl-2">
                      <Button
                        type="primary"
                        label="Approve"
                        onPress={approveBooking}
                      ></Button>
                    </View>
                  </View>
                )}
                {["verified"].includes(bookingDetails?.status) && (
                  <View className="flex-row w-full mb-4">
                    <View className="flex-1 pl-2">
                      <Button
                        type="primary"
                        label="Mark as Completed"
                        onPress={completeBooking}
                      ></Button>
                    </View>
                  </View>
                )}
                <View className="flex-1 grow w-full h-full flex-col mb-4" />
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  ) : (
    <Unauthorized />
  );
}
