import React from "react";
import {
  Image,
  Pressable,
  View,
  ScrollView,
  RefreshControl,
  Platform,
} from "react-native";
import RemixIcon from "react-native-remix-icon";
import { Font } from "../../components/font";
import { Card } from "../../components/general/cards/card";
import { Button, Select } from "../../components/input";
import colors from "../../../colors";
import { useListingContext } from "../../contexts/listing-context";
import { apiURIV2, mediaURI } from "../../constants";
import axiosInstance from "../../constants/axios-config";
import ListingPlaceholder from "../../components/general/placeholders/listing-placeholder";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { isSignedIn } from "../../actions/auth-actions";
import { ActivityIndicator } from "react-native";
import { Unauthorized } from "./unauthorized";
import { useAuthContext } from "../../contexts/auth-context";
import { catchAPIErrorText } from "../../utils/error-handler";

const initial_from = 1,
  load_records = 5;

export default function ListingList({ navigation }) {
  const { user } = useAuthContext();
  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });
  const [listingsData, setListingsData] = React.useState();
  // const [listings, setListings] = React.useState();
  // const [pagination, setPagination] = React.useState();
  const {
    tabStatus,
    setTabStatus,
    listings,
    setListings,
    pagination,
    setPagination,
  } = useListingContext();

  const [isListingFailed, setIsListingFailed] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [isFirstCame, setIsFirstCame] = React.useState(true);

  React.useEffect(() => {
    if (isFirstCame) {
      setIsFirstCame((isFirstCame) => !isFirstCame);
      setPagination({
        from: 1,
        to: load_records,
        total: pagination?.total,
      });
    }
  }, [isFirstCame, setPagination, pagination?.total]);
{console.log(pagination,"pagination check");
}
  const getListingData = async (offset, limit, status, push) => {
    setIsLoading((isLoading) => !isLoading && true);
    let data, total_count;
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.get(apiURIV2 + "/listings", {
        params: {
          offset,
          limit: limit || load_records,
          status: status ? status : undefined,
        },
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      // console.log("res.status: ", res.status, res.data);
      if (res.status === 200) {
        total_count = res.data.total_count;
        data = res.data.data;
      }
    } catch (e) {
      console.log("e.response", e?.response, e);
      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(e, "Unknown Error while Getting Listings"),
      });
      setIsListingFailed(true);
    } finally {
      const pagObj = {
        from: (offset || -1) + 1 || initial_from,
        to:
          (offset || -1) + 1
            ? offset + (data?.length || load_records)
            : data?.length || load_records,
        total: total_count,
      };
      setPagination(pagObj);
      setListings({ data, push });
      setListingsData(data);
      setIsLoading((isLoading) => isLoading && false);
    }
  };

  if (!listings && !isLoading && !isListingFailed) {
    getListingData();
  } else if (listings && !listingsData) {
    setListingsData(listings.slice(0, load_records));
  }

  if (!user) {
    return <Unauthorized />;
  } else if (isListingFailed) {
    return (
      <View className="flex-1 justify-center items-center">
        {showResponseModal.visible && (
          <ResponseModal
            visible={showResponseModal.visible}
            onRequestClose={showResponseModal.onRequestClose}
            bodyText={showResponseModal.bodyText}
          />
        )}
        <View className="p-4">
          <Font className="text-app-d font-semibold text-base" weight={700}>
            Failed to Load the Data
          </Font>
          <Font className="text-app-d1 py-2">Unable to Connect the Server</Font>
          <Button
            label="Retry"
            type="primary"
            onPress={() => {
              if (!isLoading) {
                getListingData();
                setIsListingFailed(false);
              }
            }}
          />
        </View>
      </View>
    );
  } else if (!listings || !listingsData) {
    return (
      <View className="flex-1 justify-center items-center rounded-full bg-transparent">
        {showResponseModal.visible && (
          <ResponseModal
            visible={showResponseModal.visible}
            onRequestClose={showResponseModal.onRequestClose}
            bodyText={showResponseModal.bodyText}
          />
        )}
        <ActivityIndicator
          animating={!listings && !listingsData}
          size="large"
          color={colors.app.e}
          style={{ marginBottom: 4 }}
        />
      </View>
    );
  }

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      {showResponseModal.visible && (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
        />
      )}
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
        refreshControl={
          Platform.OS === "web" ? null : (
            <RefreshControl
              refreshing={isLoading}
              onRefresh={() => {
                getListingData(undefined, undefined, tabStatus.curr);
              }}
            />
          )
        }
      >
        <View className=" max-w-screen-lg w-full mx-auto h-full">
          <View className="my-0 mb-2 lg:my-3 lg:mb-4 p-0 lg:px-4 flex-row items-center justify-between">
            <View className="flex-row items-center">
              <Font className="text-lg font-semibold text-app-d hidden lg:flex">
                Listings
              </Font>
              <Pressable
                className="bg-app-g px-1.5 py-1 rounded-full flex-row justify-center items-center self-center ml-3"
                onPress={() => navigation.navigate("add-listing")}
              >
                <RemixIcon
                  name="ri-add-circle-line"
                  color={colors.app.a}
                ></RemixIcon>
                <Font className="text-app-a px-1 pb-[2px]">Add Listing</Font>
              </Pressable>
            </View>
            <View className="w-40">
              <Select
                options={[
                  { label: "All", value: "all" },
                  // { label: "Created", value: "created" },
                  { label: "Submitted", value: "submitted" },
                  { label: "Approved", value: "approved" },
                ]}
                value={tabStatus.curr || "all"}
                setValue={(e) => {
                  if (e !== tabStatus.curr) {
                    getListingData(
                      undefined,
                      undefined,
                      e === "all" ? undefined : e
                    );
                    setTabStatus({ curr: e, prev: tabStatus.curr });
                  }
                }}
              ></Select>
            </View>
          </View>
          <View className="p-0 lg:px-4">
            <Card title="All Listing">
              <View className="relative w-full">
                {listingsData ? (
                  listingsData?.length ? (
                    listingsData?.map(
                      (
                        {
                          id,
                          title = "Unknown",
                          listing_category: { title: category_title = "" } = {},
                          country,
                          state,
                          city,
                          cover_image_url,
                          status,
                          average_rating = 0,
                          total_reviews = 0,
                          is_active,
                        } = {},
                        i
                      ) => (
                        <Pressable
                          className="p-4 border-b border-gray-200 flex-row w-full relative"
                          key={i}
                          onPress={() => {
                            is_active == false
                              ? null
                              : navigation.navigate("update-listing", {
                                  lid: id,
                                });
                          }}
                        >
                          <View className="relative self-center">
                            {status && (
                              <Font
                                className={`absolute z-10 px-2 ${
                                  Platform.OS === "web"
                                    ? "py-1"
                                    : "pb-1 pt-[2.5px]"
                                } leading-4 text-xs rounded-full mt-1 ml-1 uppercase ${
                                  status === "created"
                                    ? "bg-red-100/90 text-app-e"
                                    : status === "submitted"
                                    ? "bg-orange-100/90 text-app-h"
                                    : status === "approved"
                                    ? "bg-green-100/90 text-app-g"
                                    : ""
                                }`}
                              >
                                {status.split("_").join(" ")}
                              </Font>
                            )}
                            {cover_image_url ? (
                              <Image
                                source={{
                                  uri: mediaURI + cover_image_url,
                                }}
                                className="w-32 h-28 lg:w-40 lg:h-32 rounded-lg"
                              />
                            ) : (
                              <ListingPlaceholder
                                name={title}
                                className="w-32 h-28 lg:w-40 lg:h-32 rounded-lg"
                              />
                            )}
                          </View>
                          <View className="justify-center ml-4 flex-1">
                            <Font className="text-app-f text-sm leading-4">
                              {category_title}
                            </Font>
                            <Font
                              className={`text-md text-app-d lg:text-lg font-semibold ${
                                Platform.OS === "web" ? "mb-0" : "mb-1"
                              }`}
                              numberOfLines={2}
                            >
                              {title}
                            </Font>
                            <View
                              className="flex-row items-center"
                              style={{ paddingVertical: 4 }}
                            >
                              <RemixIcon
                                name="star-fill"
                                size={16}
                                color={colors.app.i}
                                className="mr-1 flex-shrink-0"
                              />
                              <Font
                                className="text-md text-app-d1"
                                numberOfLines={1}
                              >
                                {parseFloat(average_rating)}/5 | {total_reviews}{" "}
                                reviews
                              </Font>
                            </View>
                            <View className="flex-row">
                              <RemixIcon
                                name="ri-map-pin-fill"
                                size={16}
                                color={colors.app.d1}
                                className="mr-1 flex-shrink-0"
                              />
                              <Font
                                className="text-app-d1 text-md mb-1 lg:mb-2 leading-4"
                                weight={600}
                                numberOfLines={2}
                              >
                                {`${city ? city : "Unknown City"}${
                                  state
                                    ? ", " + state
                                    : country
                                    ? ", " + country
                                    : city
                                    ? ", Unknown State/ Country"
                                    : ""
                                }`}
                              </Font>
                            </View>
                            <View className="flex-row">
                              <RemixIcon
                                name="ri-coins-fill"
                                size={16}
                                color={colors.app.d1}
                                className="mr-1 flex-shrink-0"
                              />
                              <Font
                                className="text-app-d1 text-md mb-1 lg:mb-2 leading-4"
                                weight={600}
                                numberOfLines={2}
                              >
                                Expires on: Not set
                              </Font>
                            </View>
                          </View>
                        </Pressable>
                      )
                    )
                  ) : (
                    <View className="w-full justify-center items-center pt-4 pb-8">
                      <Font>No Listings Found</Font>
                    </View>
                  )
                ) : (
                  <View className="w-full justify-center items-center pt-4 pb-8">
                    <Font>Loading...</Font>
                  </View>
                )}
              </View>

              {load_records < pagination?.total && (
                <View className="flex-row justify-between w-full">
                  {pagination?.from > initial_from && (
                    <Pressable
                      className="p-4 "
                      onPress={() => {
                        if (pagination?.from > initial_from) {
                          const from =
                            pagination.from === 1
                              ? 1
                              : pagination.from - load_records;
                          const toModulus = pagination.to % load_records;
                          const to =
                            pagination.to -
                            (toModulus > 0
                              ? toModulus
                              : pagination.to > load_records
                              ? load_records
                              : 0);
                          const pagObj = {
                            from: from,
                            to: to,
                            total: pagination.total,
                          };
                          setPagination(pagObj);
                          // getListingData(from - 1, load_records, true);
                          setListingsData(listings.slice(from - 1, to));
                        }
                      }}
                    >
                      <Font className="text-app-e"> {"<"} Prev</Font>
                    </Pressable>
                  )}
                  <View className="flex-1" />
                  {pagination.to < pagination.total && (
                    <Pressable
                      className="p-4"
                      onPress={() => {
                        if (pagination.to < pagination.total) {
                          const remList = pagination.total - pagination.to;
                          const from =
                            pagination.from + (remList > 0 ? load_records : 0);
                          const to =
                            pagination.to +
                            (remList < load_records ? remList : load_records);
                          const pagObj = {
                            from: from,
                            to: to,
                            total: pagination.total,
                          };
                          const listData = listings.slice(from - 1, to);
                          if (listData?.length) {
                            setPagination(pagObj);
                            setListingsData(listData);
                          } else {
                            getListingData(
                              from - 1,
                              load_records,
                              tabStatus.curr,
                              true
                            );
                          }
                        }
                      }}
                    >
                      <Font className="text-app-e"> Next {">"}</Font>
                    </Pressable>
                  )}
                </View>
              )}
            </Card>
            <View className="flex-1 grow w-full h-full flex-col min-h-[16px]"></View>
          </View>
        </View>
      </ScrollView>
      {Platform.OS === "web" && isLoading ? (
        <ActivityIndicator
          animating={isLoading}
          size="small"
          color={colors.app.d}
          className={`mb-4 shadow-md rounded-full p-2 absolute self-center bg-white top-8 transform transition-all ease-in-out delay-100 duration-1000 opacity-0 ${
            isLoading && "opacity-100"
          }`}
        />
      ) : (
        <></>
      )}
    </View>
  );
}
