import React, { useContext, useEffect, useState } from "react";
import { Platform, Pressable, Text, View } from "react-native";
import { getStats, getRecentActivites } from "../../services/api";
import { Font } from "../../components/font";
import { Card } from "../../components/general/cards/card";
import { Footer } from "../../components/footer";
import { ScrollView } from "react-native";
import { Input } from "../../components/input";
import { TextInput } from "react-native-gesture-handler";

import SVGLoader from "../../utils/SVGLoader";
import search from "../../../assets/search.svg";
import verticalMenu from "../../../assets/menu-vertical.svg";
import correctCheckbox from "../../../assets/green-fill-checkbox.svg";
import cancelCheckbox from "../../../assets/gray-fill-cross.svg";
import {
  AddToGroup,
  getAllCustomers,
  getGroupNonMembersInvites,
  sendInvite,
  sendInviteToEmail,
} from "../../actions/auth-actions";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { PleaseWaitModal } from "../../components/modal";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { AuthContext } from "../../contexts/auth-context";

export default function AddInviteMember({ navigation, route }) {
  const { user } = useContext(AuthContext);
  useEffect(() => {
    if (route.params) {
      const { groupId } = route.params;
      setGroupId(groupId);
      console.log("groupId:", groupId);

      getMembersToAdd(user?.id, groupId);
      getMembersToInvite(user?.id, groupId);
    }
  }, [route.params]);
  const { checkRefreshListforMembers } = route?.params;
  // Dependency array ensures this runs only when route.params changes
  const [isNoRestrictionSelected, setIsNoRestrictionSelected] = useState(false);
  const [isInviteOnlySelected, setIsInviteOnlySelected] = useState(false);
  const [selectedNoRestrictUserIds, setSelectedNoRestrictUserIds] = useState(
    []
  );
  const [selectedInviteOnlyUserIds, setSelectedInviteOnlyUserIds] = useState(
    []
  );
  const [memberList, setMemberList] = useState([]);
  const [groupId, setGroupId] = useState();
  const [memberInviteList, setMemberInviteList] = useState([]);
  const [emailText, setEmailText] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isPleaseWaitModal, setIsPleaseWaitModal] = React.useState(false);
  const [responseModal, setResponseModal] = React.useState({
    visible: false,
    message: "",
    DismissText: "",
  });

  // useEffect(() => {
  //   const t = setTimeout(() => {
  //     if (searchText === null) {
  //       setSearchText("");
  //       getMembersToAdd(user?.id, groupId);
  //       getMembersToInvite(user?.id, groupId);
  //     }
  //   }, 1000);
  //   return () => clearTimeout(t);
  // }, [searchText]);

  const getMembersToAdd = async (id, groupId) => {
    try {
      if (!id || !groupId) return;
      console.log("getMembersToAdd groupId:", groupId);
      let res = await getGroupNonMembersInvites(id, groupId);
      console.log("Add member res:", res.data, res.status);
      if (res.status === 200) {
        setMemberList(res.data?.data);
      }
    } catch (error) {
      console.log("Error Getting Stats : ", error);
    }
  };

  const getMembersToInvite = async (id, groupId) => {
    try {
      let res = await getGroupNonMembersInvites(id, groupId);
      console.log("Invite member res:", res.data, res.status);
      if (res.status === 200) {
        setMemberInviteList(res.data?.data);
      }
    } catch (error) {
      console.log("Error Getting Stats : ", error);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      searchUsers(groupId, searchText);
    }, 1000);
  }, [searchText]);

  const searchUsers = async (search_text) => {
    try {
      let res = await getGroupNonMembersInvites(user?.id, groupId, search_text);
      console.log(res.status, res.data, "members to invite check");

      if (res.status === 200) {
        console.log(res.data, "search user check");

        const noRestrictionUsers = [];
        const inviteOnlyUsers = [];

        res?.data?.data?.forEach((user) => {
          if (user.group_joining_preference === "no_restriction") {
            noRestrictionUsers.push(user);
          }
          inviteOnlyUsers.push(user);
        });

        setMemberList(noRestrictionUsers);
        setMemberInviteList(inviteOnlyUsers);
      }
    } catch (error) {
      console.log("Error Getting Stats : ", error);
    }
  };

  const sendInvitesToNonMembers = async () => {
    try {
      console.log(" cyheck res status for invites");
      setIsPleaseWaitModal(true);
      const res = await sendInvite(groupId, selectedInviteOnlyUserIds);
      console.log(res.data, res.status, "res status for invites");

      if (res.status == 207) {
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: "Invitation sent Successfully !!!",
          DismissText: "Dismiss",
        });
        const updatedMemberList = memberInviteList.filter(
          (member) => !selectedInviteOnlyUserIds.includes(member.id)
        );
        setMemberInviteList(updatedMemberList);

        checkRefreshListforMembers();
      } else {
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: "Something went wrong !!!",
          DismissText: "Dismiss",
        });
      }
    } catch (error) {
      console.log(error, "invite error");

      setIsPleaseWaitModal(false);
    }
  };
  const AddNonMembersToGroup = async () => {
    try {
      console.log(" cyheck res status for invites");
      setIsPleaseWaitModal(true);
      const res = await AddToGroup(groupId, selectedNoRestrictUserIds);
      console.log(res.data, res.status, "res status for invites");
      if (res.status == 207) {
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: "Member added Successfully !!!",
          DismissText: "Dismiss",
        });
        const updatedMemberList = memberList.filter(
          (member) => !selectedNoRestrictUserIds.includes(member.id)
        );
        setMemberList(updatedMemberList);
        checkRefreshListforMembers();
      } else {
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: "Something went wrong !!!",
          DismissText: "Dismiss",
        });
      }
    } catch (error) {
      console.log(error, "invite error");

      setIsPleaseWaitModal(false);
    }
  };
  const sendInviteEmail = async () => {
    try {
      setIsPleaseWaitModal(true);
      const res = await sendInviteToEmail(emailText);
      console.log("Invite Email res:", res.status, res.data);
      if (res.status == 200) {
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: "Invitation sent Successfully !!!",
          DismissText: "Dismiss",
        });
        checkRefreshListforMembers();
      } else {
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: "Something went wrong !!!",
          DismissText: "Dismiss",
        });
      }
    } catch (error) {
      setIsPleaseWaitModal(false);
    }
  };

  // Function to handle checkbox toggle
  const handleCheckboxToggle = (memberId) => {
    console.log(memberId, "memberId");

    setSelectedInviteOnlyUserIds((prevSelected) => {
      if (prevSelected.includes(memberId)) {
        return prevSelected.filter((id) => id !== memberId);
      } else {
        return [...prevSelected, memberId];
      }
    });
  };
  const handleCheckboxToggleToAdd = (memberId) => {
    console.log(memberId, "memberId");

    setSelectedNoRestrictUserIds((prevSelected) => {
      if (prevSelected.includes(memberId)) {
        return prevSelected.filter((id) => id !== memberId);
      } else {
        return [...prevSelected, memberId];
      }
    });
  };

  const closeResponseModal = () => {
    setResponseModal((prev) => ({ ...prev, visible: false }));
  };

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      {isPleaseWaitModal && <PleaseWaitModal showLoader={true} />}
      <ResponseModal
        visible={responseModal.visible}
        bodyText={responseModal.message}
        onRequestClose={closeResponseModal}
      ></ResponseModal>
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View className=" max-w-screen-lg w-full mx-auto h-full">
          <View className="my-5 hidden lg:flex px-4">
            <Font className="text-lg font-semibold text-app-d">
              Add/Invite Members
            </Font>
          </View>
          <View className="flex-col justify-center item-center max-w-screen-lg w-full py-2">
            <View className="flex justify-center items-center">
              <View className=" w-full lg:w-3/5 mb-4 px-4 lg:px-0 lg:py-1 flex-row bg-white rounded-full shadow-sm">
                <View className="w-full flex-row justify-between items-center">
                  <TextInput
                    className="w-full  p-2  rounded-2xl"
                    placeholder="...Search by name, e-mail or phone"
                    placeholderTextColor="#94a3b8"
                    type="text"
                    value={searchText}
                    onChangeText={(e) => setSearchText(e || null)}
                    onSubmitEditing={(e) => {
                      searchUsers(groupId, e.nativeEvent.text);
                    }}
                    style={{ outlineStyle: "none" }}
                    returnKeyType="search"
                  />
                  <Pressable
                    onPress={() => {
                      searchUsers(groupId, searchText);
                    }}
                    className="w-8 h-8 -ml-6 lg:mr-2 lg:ml-0 justify-center items-center"
                  >
                    <RemixIcon
                      name="ri-search-line"
                      color={"#94a3b8"}
                      size={20}
                    />
                    {/* <SVGLoader
                      src={search}
                      height={25}
                      width={25}
                      webHeight={"20em"}
                      webWidth={"auto"}
                    /> */}
                  </Pressable>
                </View>
              </View>
            </View>
            <View className=" w-full">
              <Card className="flex-1">
                <View className="w-full p-4 flex-col">
                  <View className="w-full mb-3 pb-2 flex-row justify-between item-center border-b-2 border-b-gray-200">
                    <Font className="lg:text-lg text-base font font-bold self-center">
                      Add
                    </Font>
                    <View className="flex-row justify-center items-center">
                      <View className="flex-row flex justify-between items-center">
                        <Font className="px-2 text-app-d1">
                          {isNoRestrictionSelected
                            ? "Un-select All"
                            : "Select All"}
                        </Font>
                        <View className="relative overflow-hidden">
                          {isNoRestrictionSelected ? (
                            <SVGLoader
                              src={cancelCheckbox}
                              height={28}
                              width={28}
                              webHeight={"20.5px"}
                              webWidth={"20.5px"}
                              style={
                                Platform.OS === "web"
                                  ? {}
                                  : {
                                      marginTop: -1,
                                      marginBottom: -7,
                                      marginRight: -6,
                                    }
                              }
                            />
                          ) : (
                            <SVGLoader
                              src={correctCheckbox}
                              height={36}
                              width={36}
                              webHeight={"34px"}
                              webWidth={"34px"}
                              style={{
                                margin: Platform.OS === "web" ? -7 : -8,
                              }}
                            />
                          )}
                          <Pressable
                            onPress={() => {
                              if (isNoRestrictionSelected) {
                                setIsNoRestrictionSelected(() => false);
                                setSelectedNoRestrictUserIds([]);
                              } else {
                                setIsNoRestrictionSelected(() => true);
                                setSelectedNoRestrictUserIds(
                                  memberList?.map((user) => user.id) || []
                                );
                              }
                            }}
                            className="absolute top-0 left-0 bottom-0 right-0"
                          ></Pressable>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View className="min-h-[16rem] h-64  pt-0 overflow-hidden">
                    <ScrollView
                      contentContainerStyle={{
                        padding: 10,
                        paddingHorizontal: 16,
                      }}
                    >
                      {memberList.length == 0 ? (
                        <View className=" w-full flex justify-center items-center">
                          <Font
                            className="text-app-d1 text-xs items-center"
                            weight={500}
                          >
                            No Users to Add
                          </Font>
                        </View>
                      ) : (
                        memberList?.map((member) => {
                          const isSelected = selectedNoRestrictUserIds.includes(
                            member?.id
                          );
                          return (
                            <View
                              className="w-full mb-3 flex-row justify-between"
                              key={member?.id}
                            >
                              <View>
                                <Font className="font-bold text-base">
                                  {member?.first_name + " " + member?.last_name}
                                </Font>
                                <Font>
                                  {member?.email ?? "Number Not Available"}
                                </Font>
                                <Font>
                                  {member?.phone ?? "Number Not Available"}
                                </Font>
                              </View>
                              <View className="mt-2">
                                {/* <Input
                            type="checkbox"
                            value={member?.isSelected}
                            setValue={() => {
                              setIsSelected(!isSelected);
                            }}
                          /> */}
                                <Pressable
                                  style={{
                                    width: 17,
                                    height: 17,
                                    borderRadius: 2,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: isSelected
                                      ? "red"
                                      : "#f3f3f3",
                                    borderColor: isSelected ? "red" : "#ccc",
                                    borderWidth: 1,
                                  }}
                                  onPress={() =>
                                    handleCheckboxToggleToAdd(member?.id)
                                  }
                                >
                                  {isSelected ? (
                                    <RemixIcon
                                      name="check-fill"
                                      color={"#ffffff"}
                                      size={20}
                                    />
                                  ) : null}
                                </Pressable>
                              </View>
                            </View>
                          );
                        })
                      )}
                    </ScrollView>
                  </View>
                </View>
              </Card>
              <View className="my-4 flex-row justify-center lg:justify-end item-center w-full">
                <Pressable
                  onPress={() =>
                    memberList.length == 0 ? null : AddNonMembersToGroup()
                  }
                  className="px-4 py-1 border border-app-e bg-app-e rounded-full"
                >
                  <Text className="text-white">Add to Group</Text>
                </Pressable>
              </View>
              <View className="w-4 h-4" />
              <Card className="flex-1">
                <View className="w-full p-4 flex-col">
                  <View className="w-full mb-3 pb-2 flex-row justify-between item-center border-b-2 border-b-gray-200">
                    <Font className="lg:text-lg text-base font font-bold self-center">
                      Invite
                    </Font>
                    <View className="flex-row justify-center items-center">
                      <View className="flex-row flex justify-between items-center">
                        <Font className="px-2 text-app-d1">
                          {isInviteOnlySelected
                            ? "Un-select All"
                            : "Select All"}
                        </Font>
                        <View className="relative overflow-hidden">
                          {isInviteOnlySelected ? (
                            <SVGLoader
                              src={cancelCheckbox}
                              height={28}
                              width={28}
                              webHeight={"20.5px"}
                              webWidth={"20.5px"}
                              style={
                                Platform.OS === "web"
                                  ? {}
                                  : {
                                      marginTop: -1,
                                      marginBottom: -7,
                                      marginRight: -6,
                                    }
                              }
                            />
                          ) : (
                            <SVGLoader
                              src={correctCheckbox}
                              height={36}
                              width={36}
                              webHeight={"34px"}
                              webWidth={"34px"}
                              style={{
                                margin: Platform.OS === "web" ? -7 : -8,
                              }}
                            />
                          )}
                          <Pressable
                            onPress={() => {
                              if (isInviteOnlySelected) {
                                setIsInviteOnlySelected(() => false);
                                setSelectedInviteOnlyUserIds([]);
                              } else {
                                setIsInviteOnlySelected(() => true);
                                setSelectedInviteOnlyUserIds(
                                  memberInviteList?.map((user) => user.id) || []
                                );
                              }
                            }}
                            className="absolute top-0 left-0 bottom-0 right-0"
                          ></Pressable>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View className="min-h-[16rem] h-64  pt-0  overflow-hidden">
                    <ScrollView
                      contentContainerStyle={{
                        padding: 10,
                        paddingHorizontal: 16,
                      }}
                    >
                      {memberInviteList.length == 0 ? (
                        <View className=" w-full flex justify-center items-center">
                          <Font
                            className="text-app-d1 text-xs items-center"
                            weight={500}
                          >
                            No Users to Invite
                          </Font>
                        </View>
                      ) : (
                        memberInviteList?.map((member) => {
                          const isSelected = selectedInviteOnlyUserIds.includes(
                            member?.id
                          );
                          return (
                            <View
                              className="w-full mb-3 flex-row justify-between"
                              key={member?.id}
                            >
                              <View>
                                <Font className="font-bold text-base">
                                  {member?.first_name + " " + member?.last_name}
                                </Font>
                                <Font>
                                  {member?.email ?? "Number Not Available"}
                                </Font>
                                <Font>
                                  {member?.phone ?? "Number Not Available"}
                                </Font>
                              </View>
                              <View className="mt-2">
                                <Pressable
                                  style={{
                                    width: 17,
                                    height: 17,
                                    borderRadius: 2,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: isSelected
                                      ? "red"
                                      : "#f3f3f3",
                                    borderColor: isSelected ? "red" : "#ccc",
                                    borderWidth: 1,
                                  }}
                                  onPress={() =>
                                    handleCheckboxToggle(member?.id)
                                  }
                                >
                                  {isSelected ? (
                                    <RemixIcon
                                      name="check-fill"
                                      color={"#ffffff"}
                                      size={20}
                                    />
                                  ) : null}
                                </Pressable>
                              </View>
                            </View>
                          );
                        })
                      )}
                    </ScrollView>
                  </View>
                </View>
              </Card>
              <View className="my-4 flex-row justify-center lg:justify-end item-center w-full">
                <Pressable
                  onPress={() =>
                    !memberList.length ? null : sendInvitesToNonMembers()
                  }
                  className="px-4 py-1 border border-app-e bg-app-e rounded-full"
                >
                  <Text className="text-white">Send Invitation</Text>
                </Pressable>
              </View>
            </View>
          </View>
          <View className="flex-col max-w-screen-lg w-full">
            <Card className="flex-1">
              <View className="w-full p-4 flex-col">
                <View className="w-full mb-3 pb-2 flex-row justify-between item-center border-b-2 border-b-gray-200">
                  <Font className="lg:text-lg text-base font font-bold self-center">
                    Invite via email
                  </Font>
                </View>
                <View className="">
                  <View className="">
                    <TextInput
                      className="w-full ml-2 p-2 border border-gray-300 rounded-2xl"
                      placeholder="...Search by e mail"
                      placeholderTextColor="#94a3b8"
                      type="text"
                      value={emailText}
                      onChangeText={(e) => setEmailText(e)}
                      style={{ outlineStyle: "none" }}
                    />
                  </View>
                  <Pressable
                    onPress={() => sendInviteEmail()}
                    className="mt-2 flex-row justify-end item-center w-full"
                  >
                    <Font className="lg:text-lg text-sm text-app-e font-semibold">
                      Invite
                    </Font>
                  </Pressable>
                </View>
              </View>
            </Card>
          </View>
          <View className="w-full h-8" />
          <Footer />
        </View>
      </ScrollView>
    </View>
  );
}
