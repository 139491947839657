import { Platform } from "react-native";
import * as Linking from "expo-linking";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import Constants from "expo-constants";

import axiosInstance from "../constants/axios-config";
import { apiURIV2 } from "../constants";
import { isSignedIn } from "../actions/auth-actions";

const createDeepLinkUrl = ({ screen, id }) => {
  const baseUrl = "smbexvendorredirect://";
  const deepLinkUrl = Linking.createURL(`${baseUrl}/${screen}/${id}`);
  // console.log("screen : ", screen);
  // console.log("id : ", id);
  // console.log("deepLink : ", deepLinkUrl);
  // console.log("----------------------");
  // console.log("\n\n\n");

  return deepLinkUrl;
};

export const useNotifications = () => {
  const registerPushNotificationApiCall = async (notification_token) => {
    if (!notification_token) return false;
    try {
      const auth_token = await isSignedIn();
      if (!auth_token) return;
      const res = await axiosInstance.post(
        apiURIV2 + "/notifications/tokens",
        {
          user_device_token: notification_token,
        },
        {
          headers: {
            Authorization: `Bearer ${auth_token}`,
          },
        }
      );

      if (res.status === 204) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error("registerPushNotificationApiCall error:", e);
    }
  };

  const registerForPushNotificationsAsync = async () => {
    if (Platform.OS === "web") {
      console.log("Push notifications are not supported on the web platform.");
      return null;
    }
    if (Platform.OS === "android") {
      await Notifications.setNotificationChannelAsync("default", {
        name: "default",
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: "#FF0000DD",
      });
    }
    let token;
    if (Device.isDevice || Platform.OS === "android") {
      const { status: existingStatus } =
        await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== "granted") {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== "granted") {
        console.log("Notifications are not granted by app!");
        throw new Error("Failed to get device token for push notification!");
      }
      token = (
        await Notifications.getExpoPushTokenAsync({
          projectId:
            Constants?.expoConfig?.extra?.eas?.projectId ??
            Constants?.easConfig?.projectId,
        }).catch((e) => {
          console.log("Notifications.getExpoPushTokenAsync() Error:", e);
          if (e?.code === "ERR_NOTIFICATIONS_NETWORK_ERROR") {
            throw new Error("Network issue detected");
          } else {
            throw new Error(
              "Internal Error occured to setup Push Notifications!"
            );
          }
        })
      )?.data;
      console.log("PUSH NOTIFICATION TOKEN:", token);
      if (token) await registerPushNotificationApiCall(token);
      return token;
    } else {
      alert("Must use physical device for Push Notifications");
    }
  };
  // This listener is fired whenever a notification is received while the app is foreground
  const handleNotification = async (notification) => {
    // could be useful if you want to display your own toast
    // could also make a server call to refresh data in other part of the app
    // console.log(notification, "notification");
    return {
      shouldShowAlert: true,
      shouldPlaySound: true,
      shouldSetBadge: true,
    };
  };

  // This listener is fired whenever a user laps on or interacts with a notification [works when app is foreground, background]

  const handleNotificationResponse = async (response) => {
    const data = response?.notification?.request?.content?.data;
    // console.log("\nNotification data:", data, "\n");
    // console.log(
    //   "\nresponse.notification:",
    //   JSON.stringify(response.notification, undefined, 1),
    //   "\n"
    // );
    if (data?.screen === "chat") {
      // Navigate to the specified screen
      if (Platform.OS === "web") {
        window.open(data.screen);
        // return null;
      } else {
        try {
          const deepLinkUrl = createDeepLinkUrl({
            screen: data.screen,
            id: data.channel_details?.customer_id,
          });
          await Linking.openURL(deepLinkUrl);
        } catch (error) {
          console.error("Error opening URL:", error);
        }
      }
    }
    if (data?.screen === "booking_details") {
      // Navigate to the specified screen
      if (Platform.OS === "web") {
        window.open(data.screen);
        // return null;
      } else {
        try {
          const deepLinkUrl = createDeepLinkUrl({
            screen: data.screen,
            id: data.booking_id,
          });
          await Linking.openURL(deepLinkUrl);
        } catch (error) {
          console.error("Error opening URL:", error);
        }
      }
    }
    if (data?.screen === "order_details") {
      if (Platform.OS === "web") {
        window.open(data.screen);
      } else {
        try {
          const deepLinkUrl = createDeepLinkUrl({
            screen: data.screen,
            id: data.product_order_id,
          });
          await Linking.openURL(deepLinkUrl);
        } catch (error) {
          console.error("Error opening URL:", error);
        }
      }
    }
  };

  return {
    registerForPushNotificationsAsync,
    handleNotification,
    handleNotificationResponse,
  };
};
