import React, { useCallback } from "react";
import {
  FlatList,
  Image,
  Pressable,
  ScrollView,
  Text,
  View,
} from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/general/cards/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Button, Input, Select, Switcher } from "../../components/input";
import { UserContext } from "../../services/api";
import { Unauthorized } from "./unauthorized";
import { AuthContext } from "../../contexts/auth-context";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { useOrdersContext } from "../../contexts/orders-context";
import { isSignedIn } from "../../actions/auth-actions";
import axiosInstance from "../../constants/axios-config";
import { apiURIV2, mediaURI } from "../../constants";
import { Platform } from "react-native";
import { RefreshControl } from "react-native";
import { getFullDateTime } from "../../utils/date-time-util";
import { cloneDeep } from "lodash";
import { orderStatusBadgeData } from "../../utils/statusBadgeValues";
import useInputHandler from "../../utils/useInputHandler";
import { catchAPIErrorText } from "../../utils/error-handler";
export default function OrderDetails({ navigation, route }) {
  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });

  const { user } = React.useContext(AuthContext);
  const {
    orders,
    setOrders,
    // orderDetailsData,
    // setOrderDetailsData,
  } = useOrdersContext();

  const [oDIsLoading, setODIsLoading] = React.useState();
  const [orderDetails, setOrderDetails] = React.useState();
  const [trackingLinkStatus, setTrackingLinkStatus] = React.useState({
    visible: false,
    link: undefined,
    error: undefined,
    touched: false,
    sharing: false,
  });
  const oid = route.params?.oid;
  if (!oid) {
    navigation.replace("orders");
  }

  const uploadTrackingLink = useCallback(async (tracking_link) => {
    if (tracking_link !== "" && !tracking_link) {
      return;
    }
    setTrackingLinkStatus((v) => ({ ...v, sharing: true }));
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.put(
        apiURIV2 + "/orders/product/" + oid + "/tracking-link",
        { tracking_link: tracking_link },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        console.log("res.data.message", res?.data?.message);
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: res.data.message || "Tracking link updated!",
          dismissText: "Okay",
        });
        setTrackingLinkStatus(() => ({
          visible: !!tracking_link,
          link: tracking_link,
        }));
        setOrderDetails((data) => {
          data.tracking_link = tracking_link;
          return data;
        });
      }
    } catch (e) {
      console.log("e.res", e?.response, e);
      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Sharing Tracking Link"
        ),
      });
    } finally {
      setTrackingLinkStatus((v) => ({ ...v, sharing: false }));
    }
  }, []);

  const getOrderDetails = async () => {
    setODIsLoading(true);
    let data;
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.get(apiURIV2 + "/orders/product/" + oid, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("res.status: ", res.status, res.data);
      if (res.status === 200) {
        data = res.data.data;
      }
    } catch (e) {
      console.log("e.res", e?.response, e);
      if ([404].includes(e?.response?.status)) {
        return navigation.replace("orders");
      }
    } finally {
      if (data) {
        // setOrderDetailsData({ data: parseTime(data), id: oid });
        setOrderDetails(data);
        if (data.tracking_link) {
          setTrackingLinkStatus((v) => ({
            ...v,
            visible: true,
            link: data.tracking_link,
          }));
        }
      }
      setODIsLoading(false);
    }
  };

  if (!orderDetails && !oDIsLoading) {
    getOrderDetails();
  }

  if (!orderDetails) {
    return (
      <View className="flex-1 justify-center items-center">
        {showResponseModal.visible && (
          <ResponseModal
            visible={showResponseModal.visible}
            onRequestClose={showResponseModal.onRequestClose}
            bodyText={showResponseModal.bodyText}
            dismissText={showResponseModal?.dismissText}
          />
        )}
        <Font>Loading...</Font>
      </View>
    );
  }

  const cancelOrder = async () => {
    setODIsLoading(true);
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.post(
        apiURIV2 + "/orders/product/" + oid + "/cancel",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        let message = res.data?.message;
        if (message) {
          setShowResponseModal({
            visible: true,
            onRequestClose: () => {
              setShowResponseModal({
                visible: false,
                onRequestClose: () => null,
                bodyText: "",
              });
            },
            bodyText: message,
          });
        }
        setOrderDetails((data) => {
          data.status = "cancelled_by_vendor";
          return data;
        });
        if (orders) {
          const updatedData = cloneDeep(orders);
          const i = updatedData?.findIndex((v) => v.id == oid);
          updatedData[i].status = "cancelled_by_vendor";
          setOrders({ data: updatedData });
        }
      }
    } catch (e) {
      console.log("e.res", e?.response, e);
      console.log("error message:", bodyText);
      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Cancelling Products"
        ),
      });
    } finally {
      setODIsLoading(false);
    }
  };

  let status = orderDetails.status,
    orderStatus = orderStatusBadgeData(status);

  return user ? (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      {showResponseModal.visible && (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
          dismissText={showResponseModal?.dismissText}
        />
      )}
      <View className="rounded-t-2xl lg:rounded-none overflow-hidden flex-1 bg-gray-50">
        <ScrollView
          className="rounded-t-2xl p-4 lg:pt-0"
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
          refreshControl={
            Platform.OS === "web" ? null : (
              <RefreshControl
                refreshing={oDIsLoading}
                onRefresh={() => {
                  getOrderDetails();
                }}
              />
            )
          }
        >
          <View className="flex-1 h-full w-full lg:px-4 items-center">
            <View className="my-5 hidden lg:flex flex-row w-full max-w-screen-lg">
              <Font className="text-lg font-semibold text-app-d flex-1">
                Order Details
              </Font>
            </View>
            <View className="flex-col lg:flex-row lg:max-w-screen-lg w-full">
              <View className=" w-full lg:w-4/6 p-0 lg:pr-4 ">
                <Card
                  title="Order Info"
                  Widget={() => (
                    <View
                      className={`flex-row items-center -mb-2 px-2 -py-2 rounded border ${orderStatus.bgColor} ${orderStatus.borderColor}`}
                      // style={{
                      //   marginVertical: 2,
                      // }}
                    >
                      <Font
                        className={`uppercase text-xs ${orderStatus.textColor}`}
                        weight={600}
                        numberOfLines={1}
                      >
                        {orderStatus.text}
                      </Font>
                    </View>
                  )}
                >
                  <View className="w-full flex-row flex-wrap px-4 pb-4">
                    <View className="mb-2 w-full">
                      <Font className="text-app-d" weight={600}>
                        Order ID:
                      </Font>
                      <Font className="text-app-d1" weight={500}>
                        # {orderDetails.id}
                      </Font>
                    </View>
                    <View className="mb-2 w-full lg:w-1/2">
                      <Font className="text-app-d" weight={600}>
                        Order Placed on:
                      </Font>
                      <Font className="text-app-d1" weight={500}>
                        {getFullDateTime(orderDetails.created_at)}
                      </Font>
                    </View>
                    <View className="mb-2 w-full lg:w-1/2">
                      <Font className="text-app-d" weight={600}>
                        Last Updated on:
                      </Font>
                      <Font className="text-app-d1" weight={500}>
                        {getFullDateTime(orderDetails.updated_at)}
                      </Font>
                    </View>
                    {![
                      "created",
                      "failed",
                      "cancelled_by_vendor",
                      "cancelled_by_customer",
                    ].includes(orderDetails.status) && (
                      <View className="w-full lg:w-1/2">
                        <Font className="text-app-d" weight={600}>
                          Order Status:
                        </Font>
                        <Select
                          className="mt-1"
                          options={[
                            { label: "Processing Order", value: "processing" },
                            {
                              label: "Ready to Pickup",
                              value: "ready_for_pickup",
                            },
                            {
                              label: "Completed Pickup",
                              value: "picked",
                            },
                            {
                              label: "Order Shipped",
                              value: "shipped",
                            },
                            {
                              label: "Out for Delivery",
                              value: "out_for_delivery",
                            },
                            {
                              label: "Delivered",
                              value: "delivered",
                            },
                          ]}
                          value={orderDetails.status}
                          setValue={(v) => {
                            (async (status) => {
                              const token = await isSignedIn();
                              axiosInstance
                                .put(
                                  apiURIV2 +
                                    "/orders/product/" +
                                    oid +
                                    "/status",
                                  { status: status },
                                  {
                                    headers: {
                                      Authorization: "Bearer " + token,
                                    },
                                  }
                                )
                                .then((res) => {
                                  console.log(
                                    "res.status: ",
                                    res.status,
                                    res.data
                                  );
                                  if (res.status === 200) {
                                    console.log(
                                      "res.data.message",
                                      res?.data?.message
                                    );
                                    let message = res.data?.message;
                                    if (message) {
                                      setShowResponseModal({
                                        visible: true,
                                        onRequestClose: () => {
                                          setShowResponseModal({
                                            visible: false,
                                            onRequestClose: () => null,
                                            bodyText: "",
                                          });
                                        },
                                        bodyText: message,
                                      });
                                    }
                                    setOrderDetails((data) => {
                                      data.status = status;
                                      return data;
                                    });
                                    if (orders) {
                                      const updatedData = cloneDeep(orders);
                                      const i = updatedData?.findIndex(
                                        (v) => v.id == oid
                                      );
                                      updatedData[i].status = status;
                                      setOrders({ data: updatedData });
                                    }
                                  }
                                })
                                .catch((e) => {
                                  console.log("e.res", e?.response, e);
                                  setShowResponseModal({
                                    visible: true,
                                    onRequestClose: () => {
                                      setShowResponseModal({
                                        visible: false,
                                        onRequestClose: () => null,
                                        bodyText: "",
                                      });
                                    },
                                    bodyText: catchAPIErrorText(
                                      e,
                                      "Unknown Error while Getting Products"
                                    ),
                                  });
                                });
                            })(v);
                          }}
                        />
                      </View>
                    )}
                  </View>
                </Card>
                <Card title="Order Items" className="flex-auto">
                  <View className="w-full p-4 pt-0 divide-y divide-gray-200">
                    <FlatList
                      className="w-full"
                      data={orderDetails.product_order_items}
                      keyExtractor={(item, index) =>
                        `item_${item?.id}_${index}`
                      }
                      renderItem={({ item, index: i }) => {
                        const variant = item.ordered_variant;
                        const currencySymbol = orderDetails.currency_symbol;
                        return (
                          <Pressable
                            key={"items" + i}
                            className="w-full flex-row pb-4 justify-center items-center border-b mb-4 border-gray-200"
                            onPress={() => {
                              navigation.navigate("update-product", {
                                pid: item.product_id,
                                vid: item.variant_id,
                              });
                            }}
                          >
                            <View className="h-28 w-28 rounded overflow-hidden">
                              <Image
                                source={
                                  variant?.cover_image_url
                                    ? {
                                        uri:
                                          mediaURI + variant?.cover_image_url,
                                      }
                                    : ""
                                }
                                className="w-full h-full  bg-slate-200"
                              ></Image>
                            </View>
                            <View className="flex-1 px-4 justify-between">
                              <View className="justify-self-start">
                                <Font
                                  className="text-app-d font-semibold leading-5 text-base"
                                  weight={700}
                                  numberOfLines={2}
                                >
                                  {variant?.title}
                                </Font>
                                <Font
                                  className="text-app-d1 pb-1"
                                  numberOfLines={4}
                                >
                                  {variant?.description}
                                </Font>
                              </View>
                              <View className="justify-self-end">
                                <View className="flex-row">
                                  <Font className="text-app-d" weight={600}>
                                    Price:{" "}
                                  </Font>
                                  <Font className="text-app-d" weight={500}>
                                    {currencySymbol}{" "}
                                  </Font>
                                  <Font
                                    className="line-through text-app-e1"
                                    weight={500}
                                  >
                                    {item?.price ? item?.price / 100 : ""}
                                  </Font>
                                  <Font
                                    className="text-app-d px-1"
                                    weight={500}
                                  >
                                    {item?.sale_price
                                      ? item?.sale_price / 100
                                      : "-"}
                                  </Font>
                                </View>
                                <View className="flex-row">
                                  <Font className="text-app-d" weight={600}>
                                    Qty.:{" "}
                                  </Font>
                                  <Font className="text-app-d" weight={500}>
                                    {item?.quantity}
                                  </Font>
                                </View>
                              </View>
                            </View>
                            <View className="p-2">
                              <Font
                                className="text-[15px] text-app-d"
                                weight={600}
                              >
                                {currencySymbol}{" "}
                                {item?.purchase_amount
                                  ? (item?.purchase_amount || 0) / 100
                                  : "-"}
                              </Font>
                            </View>
                          </Pressable>
                        );
                      }}
                    />
                  </View>
                  <View className="flex flex-col grow shrink basis-auto" />
                  <View className="w-full flex-row px-5 pb-4 pt-2 border-t border-app-c">
                    <Font className="text-[15px] text-app-d" weight={600}>
                      Grand Total:
                    </Font>
                    <View className="flex-1 w-full" />
                    <Font
                      className="text-app-d font-semibold text-base"
                      weight={700}
                    >
                      {orderDetails?.currency_symbol}{" "}
                      {orderDetails?.total_product_order_amount

                        ? orderDetails?.total_product_order_amount
                        / 100
                        : "-"}
                    </Font>
                  </View>
                </Card>
              </View>
              <View className=" w-full lg:w-2/6 p-0 lg:pl-4">
                <Card
                  title="Order Tracking Link"
                  Widget={() => (
                    <View className="w-8">
                      <Input
                        type="toggle"
                        wait={
                          !trackingLinkStatus.link && trackingLinkStatus.sharing
                        }
                        disabled={[
                          "created",
                          "failed",
                          "cancelled_by_vendor",
                          "cancelled_by_customer",
                        ].includes(orderDetails.status)}
                        value={trackingLinkStatus.visible}
                        setValue={() => {
                          if (
                            [
                              "created",
                              "failed",
                              "cancelled_by_vendor",
                              "cancelled_by_customer",
                            ].includes(orderDetails.status)
                          ) {
                            return;
                          }
                          if (orderDetails.tracking_link) {
                            uploadTrackingLink("");
                          }
                          setTrackingLinkStatus((v) => ({
                            ...v,
                            visible: !v.visible,
                            link: v.visible && v.link ? "" : v.link,
                            error:
                              !v.visible && v.link
                                ? /^(?:(?:https?):\/\/)?(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/[^\s]*)?$/.test(
                                    v.link
                                  )
                                  ? undefined
                                  : "Invalid URL"
                                : undefined,
                            touched: v.touched,
                          }));
                        }}
                      />
                    </View>
                  )}
                >
                  {trackingLinkStatus.visible ? (
                    <View className="px-4 pb-4 w-full">
                      {/* <View className="px-2 w-full lg:w-1/3 pb-4"></View> */}
                      <View className="w-full">
                        <Input
                          type="text"
                          // label="State"
                          placeholder="https://"
                          value={trackingLinkStatus.link}
                          onChangeText={(text) => {
                            text = (text || "").toLowerCase();
                            setTrackingLinkStatus((v) => ({
                              ...v,
                              link: text,
                              error:
                                (v.error || v.touched) &&
                                text &&
                                (/^(?:(?:https?):\/\/)?(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/[^\s]*)?$/.test(
                                  text
                                )
                                  ? undefined
                                  : "Invalid URL"),
                            }));
                          }}
                          onBlur={() => {
                            setTrackingLinkStatus((v) => ({
                              ...v,
                              error:
                                v.link &&
                                (/^(?:(?:https?):\/\/)?(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/[^\s]*)?$/.test(
                                  v.link
                                )
                                  ? undefined
                                  : "Invalid URL"),
                              touched: true,
                            }));
                          }}
                          note={trackingLinkStatus.error}
                          autoFocus={!trackingLinkStatus.link}
                        />
                      </View>
                      <View className="w-full mt-4">
                        <Button
                          label="Share Link"
                          type="primary"
                          onPress={uploadTrackingLink.bind(
                            null,
                            trackingLinkStatus.link
                          )}
                          waitState={trackingLinkStatus.sharing}
                          disabled={
                            !!trackingLinkStatus.error ||
                            (!!trackingLinkStatus.link &&
                              orderDetails.tracking_link ===
                                trackingLinkStatus.link)
                          }
                        ></Button>
                      </View>
                    </View>
                  ) : (
                    <></>
                  )}
                </Card>
                <Card title="Order Payment">
                  <View className="w-full flex-row flex-wrap p-4 pt-0">
                    <View className="w-full flex-row justify-between pb-2">
                      <Font className="text-app-d mr-1" weight={600}>
                        Payment Method:
                      </Font>
                      <Font className="text-app-d1" weight={500}>
                        {["created"].includes(orderDetails.status)
                          ? "- (Payment is still pending...)"
                          : "-"}
                      </Font>
                    </View>
                    <View className="w-full flex-row justify-between pb-2">
                      <Font className="text-app-d mr-1" weight={600}>
                        Total Price:
                      </Font>
                      <Font className="text-app-d1" weight={500}>
                        {`${
                          orderDetails?.total_product_order_amount

                            ? (orderDetails?.currency_symbol ||
                                "") +
                              " " +
                              orderDetails?.total_product_order_amount
                              /
                                100
                            : "-"
                        }`}
                      </Font>
                    </View>
                    <View className="w-full flex-row justify-between pb-2">
                      <Font className="text-app-d mr-1" weight={600}>
                        Applied Coupon:
                      </Font>
                      <Font className="text-app-d text-base" weight={600}>
                        {orderDetails?.product_order_items[0]?.applied_coupon
                          ?.code || "-"}
                      </Font>
                    </View>
                    <View className="w-full flex-row justify-between mb-2">
                      <Font className="text-app-d mr-1" weight={600}>
                        Coupon amount:
                      </Font>
                      <Font className="text-app-g" weight={500}>
                        - ${" "}
                        {+(
                          orderDetails?.product_order_items[0]?.applied_coupon
                            ?.coupon_discount_amount || 0
                        ) / 100}
                      </Font>
                    </View>
                    {/* <View className="w-full flex-row justify-between pb-2">
                      <Font className="text-app-d mr-1" weight={600}>
                        Wallet Amount:
                      </Font>
                      <Font
                        className={`${
                          orderDetails?.master_order?.redeemed_rewards
                            ?.discount_amount
                            ? "text-app-g"
                            : "text-app-d1"
                        }`}
                        weight={500}
                      >
                        {orderDetails?.master_order?.redeemed_rewards
                          ?.discount_amount
                          ? ("- " +
                              orderDetails?.master_order?.currency_symbol ||
                              "") +
                            " " +
                            orderDetails?.master_order?.redeemed_rewards
                              ?.discount_amount /
                              100
                          : "-"}
                      </Font>
                    </View> */}
                    <View className="w-full flex-row justify-between pb-2">
                      <Font className="text-app-d mr-1" weight={600}>
                        Shipping Cost:
                      </Font>
                      <Font className="text-app-d1" weight={500}>
                        -
                      </Font>
                    </View>
                    {/* <View className="w-full flex-row justify-between pb-2">
                        <Font className="text-app-d mr-1" weight={600}>
                          Wallet Amount:
                        </Font>
                        <Font className="text-app-d1" weight={500}>
                          -
                        </Font>
                      </View> */}
                    <View className="w-full flex-row justify-between pb-2">
                      <Font className="text-app-d mr-1 text-base" weight={700}>
                        Total Payment:
                      </Font>
                      <Font className="text-app-d1 text-base" weight={600}>
                        {`${
                          orderDetails?.net_product_order_amount
                            ? (orderDetails?.currency_symbol || "") +
                              " " +
                              orderDetails?.net_product_order_amount / 100
                            : "-"
                        }`}
                      </Font>
                    </View>
                  </View>
                </Card>
                <Card title="Shipment Info">
                  <View className="w-full flex-row flex-wrap p-4 pt-0">
                    <View className="w-full flex-row justify-between mb-2">
                      <Font className="text-app-d mr-1" weight={600}>
                        Shipping via:
                      </Font>
                      <Font className="text-app-d1" weight={500}>
                        -
                      </Font>
                    </View>
                    <View className="w-full flex-row justify-between mb-2">
                      <Font className="text-app-d mr-1" weight={600}>
                        Contact Number:
                      </Font>
                      <Font className="text-app-d1" weight={500}>
                        {orderDetails?.master_order?.shipping_address
                          ? orderDetails?.master_order.shipping_address?.address
                              ?.phone
                          : "-"}
                      </Font>
                    </View>
                    <View className="w-full flex-row justify-between mb-2">
                      <Font className="text-app-d mr-1" weight={600}>
                        Tracking Number:
                      </Font>
                      <Font className="text-app-d1" weight={500}>
                        -
                      </Font>
                    </View>
                    <View className="w-full flex-row justify-between mb-2">
                      <Font
                        className="text-app-d mr-1 w-full flex-row shrink grow-0 basis-auto"
                        weight={600}
                      >
                        Shipping Address:
                      </Font>
                      <Font
                        className="text-app-d1 flex-row grow-0 shrink basis-auto"
                        weight={500}
                      >
                        {(() => {
                          const addr =
                            orderDetails?.master_order?.shipping_address
                              ?.address;
                          if (addr)
                            return `${addr.address_line_1}, ${addr.address_line_2}, ${addr.locality}, ${addr.city}, ${addr.state}, ${addr.country}, ${addr.postal_code}`;
                          else return "-";
                        })()}
                      </Font>
                    </View>
                  </View>
                </Card>
                {/* <Card title="Review">
                  <View className="flex-1 w-full p-4 pt-0">
                    <View className="mb-4">
                      <Input type="rating"></Input>
                    </View>
                    <View>
                      <Input type="longtext"></Input>
                    </View>
                  </View>
                </Card> */}
              </View>
            </View>
          </View>
          {["created", "confirmed"].includes(orderDetails.status) && (
            <View className="mb-4 mr-4 w-full lg:self-end lg:w-72">
              <Button
                type="primary"
                label="Cancel Order"
                onPress={cancelOrder}
              ></Button>
            </View>
          )}
          {Platform.OS !== "web" && <Card></Card>}
        </ScrollView>
      </View>
    </View>
  ) : (
    <Unauthorized />
  );
}
