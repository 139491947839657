import React, { useCallback, useLayoutEffect } from "react";
import {
  ActivityIndicator,
  FlatList,
  Image,
  Pressable,
  View,
  KeyboardAvoidingView,
} from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";
// import { MessagesContent } from "./messages-content";
import { Unauthorized } from "./unauthorized";
import { ScrollView, TextInput } from "react-native";
import { Font } from "../../components/font";
import colors from "../../../colors";
import RemixIcon from "react-native-remix-icon";
import { Platform } from "react-native";
import { AuthContext } from "../../contexts/auth-context";
import { apiURIV2, mediaURI } from "../../constants";
import { useSharedContext } from "../../contexts/shared-context";
import { getFullDateWithWeekday, getTime } from "../../utils/date-time-util";
import { parseProfileName } from "../../utils";
import { useMessagesContext } from "../../contexts/messages-context";
import axiosInstance from "../../constants/axios-config";
import { isSignedIn } from "../../actions/auth-actions";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { RefreshControl } from "react-native";
import { DefaultImages } from "../../constants/predefined-constants";
import NetInfo from "@react-native-community/netinfo";
import { catchAPIErrorText } from "../../utils/error-handler";

const initial_from = 1,
  load_records = 10;

const Drawer = createDrawerNavigator();

export default function Messages({ navigation, route }) {
  const { user } = React.useContext(AuthContext);
  const {
    redirectChatUserID,
    setRedirectChatUserID,
    currentChatData,
    setCurrentChatData,
    channels,
    setChannels,
    // scrollToEnd,
    // setScrollToEnd,
    currentChannelMessages,
    setCurrentChannelMessages,
    currentChannelMessagesPagination,
    setCurrentChannelMessagesPagination,
    updateCurrentChannelMessages,
    deleteCurrentChannelMessages,
    dispatchMessageEvents,
  } = useMessagesContext();
  const {
    screen: { width },
  } = useSharedContext();
  const isLargeScreen = width >= 1024;

  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });

  const [messages, setMessages] = React.useState();
  const [messageText, setMessageText] = React.useState("");
  const [isTyping, setIsTyping] = React.useState(null);

  const [currChatData, setCurrChatData] = React.useState();

  const [vCIsLoading, setVCIsLoading] = React.useState();

  React.useEffect(() => {
    if (currentChatData.id) {
      if (isTyping) {
        dispatchMessageEvents.startedTypingMessage({
          channel_id: currentChatData.id,
        });
      } else {
        dispatchMessageEvents.stoppedTypingMessage({
          channel_id: currentChatData.id,
        });
      }
    }
  }, [isTyping, currentChatData.id]);

  const getVendorChannels = async (offset, limit, push) => {
    setVCIsLoading(true);
    let data, total_count;
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.get(apiURIV2 + "/chat/channels", {
        // params: { offset, limit: limit || load_records },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        total_count = res.data.total_count;
        data = res.data?.data || [];
      }
    } catch (e) {
      console.log("vendors e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Getting Vendor Channels"
        ),
      });
    } finally {
      // const pagObj = {
      //   from: (offset || -1) + 1 || initial_from,
      //   to:
      //     (offset || -1) + 1
      //       ? offset + (data?.length || load_records)
      //       : data?.length || load_records,
      //   total: total_count,
      // };
      setChannels({ data: data || [] });
      setVCIsLoading(false);
    }
  };

  if (!channels && !vCIsLoading) {
    getVendorChannels();
  }

  const onChatPress = ({ data, index }) => {
    setCurrentChatData({
      currIndex: index,
      data: data,
      self_user_id: user.id,
    });
    setIsTyping(false);
    setMessageText("");
  };

  const sendMessageHandler = async ({ message, channel_id }) => {
    const data = {
      id: "local_" + Date.now(),
      sender_id: user.id,
      sender_role: "vendor",
      message: message,
      image_url: user.profile_image_url,
      timestamp: null,
      // "created_at": "2023-06-12T19:50:35.151Z",
      // "updated_at": "2023-06-12T19:50:35.151Z"
    };

    try {
      const state = await NetInfo.fetch();

      if (state.isConnected) {
        // setCurrentChannelMessages({ data: data, push: true });
        // const response = await fetch("https://www.google.com", {
        //   method: "HEAD",
        // });
        // if (response.ok) {
        dispatchMessageEvents
          .sendMessage({
            channel_id,
            message,
          })
          .then((res) => {
            if (res.status === "error") {
              setShowResponseModal({
                visible: true,
                onRequestClose: () =>
                  setShowResponseModal({
                    visible: false,
                    onRequestClose: () => null,
                    bodyText: "",
                  }),
                bodyText: `${res.error.event}: ${res.error.message}`,
              });
              deleteCurrentChannelMessages({
                channel_id,
                message_id: data.id,
                sender_id: user.id,
              });
            }
          })
          .catch((err) => {
            deleteCurrentChannelMessages({
              channel_id,
              message_id: data.id,
              sender_id: user.id,
            });
          });
      } else {
        alert("Connected network does not have internet access");
      }
      // } else {
      //   alert("No Internet please connect");
      //   // setCurrentChannelMessages({ data: data, push: true });
      // }
    } catch (e) {
      alert("No Internet");
      // setCurrentChannelMessages({ data: data, push: true });
    }
  };

  const onDeleteMessage = ({ channel_id, message_id, messageIndex, data }) => {
    updateCurrentChannelMessages({
      channel_id,
      message_id,
      messageIndex,
      data: { ...data, isDeleting: true },
    });
    dispatchMessageEvents
      .deleteMessage({
        channel_id: channel_id,
        message_id: message_id,
      })
      .then((res) => {
        if (res.status === "error") {
          updateCurrentChannelMessages({
            channel_id,
            message_id,
            messageIndex,
            data,
          });
          setShowResponseModal({
            visible: true,
            onRequestClose: () =>
              setShowResponseModal({
                visible: false,
                onRequestClose: () => null,
                bodyText: "",
              }),
            bodyText: `${res.error.event}: ${res.error.message}`,
          });
        }
      })
      .catch((err) => {
        updateCurrentChannelMessages({
          channel_id,
          message_id,
          messageIndex,
          data,
        });
      });
  };

  return user ? (
    <View className="bg-gray-50 h-full w-full">
      {showResponseModal.visible && (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
        />
      )}
      <View className="flex-1 items-center lg:mx-8">
        <View className="w-full lg:max-w-screen-lg margin-auto">
          <View className="my-5 hidden lg:flex flex-row w-full max-w-screen-lg pt-4">
            <Font className="text-lg font-semibold text-app-d flex-1">
              Messages
            </Font>
          </View>
        </View>
        <View className="w-full lg:max-w-screen-lg h-full lg:h-4/5  margin-auto lg:border lg:border-gray-200 lg:rounded-xl overflow-hidden">
          <Drawer.Navigator
            backBehavior="none"
            defaultStatus="open"
            drawerContent={(props) => (
              <ChatList
                {...props}
                isLargeScreen={isLargeScreen}
                onChatPress={onChatPress}
                currentChatData={currentChatData}
                setCurrentChatData={setCurrentChatData}
                setShowResponseModal={setShowResponseModal}
                channels={channels}
                isChannelsLoading={vCIsLoading}
                setChannels={setChannels}
                redirectChatUserID={redirectChatUserID}
                setRedirectChatUserID={setRedirectChatUserID}
                onRefresh={getVendorChannels}
              />
            )}
            screenOptions={{
              headerShown: false,
              drawerType: isLargeScreen ? "permanent" : "back",
              drawerStyle: isLargeScreen ? null : { width: "100%" },
              drawerContentContainerStyle: { paddingTop: 4 },
              overlayColor: "transparent",
            }}
          >
            <Drawer.Screen name="chat" key={currentChatData?.id}>
              {(props) => (
                <MessagesContent
                  {...props}
                  isTyping={isTyping}
                  // scrollToEnd={scrollToEnd}
                  // setScrollToEnd={setScrollToEnd}
                  setIsTyping={setIsTyping}
                  messageText={messageText}
                  setMessageText={setMessageText}
                  isLargeScreen={isLargeScreen}
                  messages={messages}
                  user={user}
                  currentChat={currChatData}
                  onSendMessage={sendMessageHandler}
                  currentChatData={currentChatData}
                  setCurrentChatData={setCurrentChatData}
                  currentChannelMessages={currentChannelMessages}
                  setCurrentChannelMessages={setCurrentChannelMessages}
                  setShowResponseModal={setShowResponseModal}
                  dispatchMessageEvents={dispatchMessageEvents}
                  currentChannelMessagesPagination={
                    currentChannelMessagesPagination
                  }
                  setCurrentChannelMessagesPagination={
                    setCurrentChannelMessagesPagination
                  }
                  onDeleteMessage={onDeleteMessage}
                />
              )}
            </Drawer.Screen>
          </Drawer.Navigator>
        </View>
      </View>
    </View>
  ) : (
    <Unauthorized />
  );
}

export const ChatList = ({
  navigation,
  isLargeScreen,
  onChatPress,
  channels,
  currentChatData,
  redirectChatUserID,
  setRedirectChatUserID,
  onRefresh,
  isChannelsLoading,
}) => {
  const [isRefreshing, setIsRefreshing] = React.useState(false);
  React.useEffect(() => {
    if (channels?.length && redirectChatUserID) {
      const index = channels.findIndex(
        (ch) => ch.customer_id == redirectChatUserID
      );
      if (index > -1) {
        onChatPress({ data: channels[index], index: index });
        setRedirectChatUserID({ uid: null });
        navigation.closeDrawer();
      }
    }
  }, [
    channels,
    onChatPress,
    redirectChatUserID,
    setRedirectChatUserID,
    navigation,
  ]);

  const chatPressHandler = (chatData, chatIndex) => {
    onChatPress({ index: chatIndex, data: chatData });
    navigation.closeDrawer();
  };

  return (
    <View className="flex-1 lg:bg-transparent">
      <View className="w-full h-16 bg-app-e items-center flex-row px-2 flex lg:hidden">
        <Font className="text-app-a lg:text-app-d font-semibold text-[15px] flex lg:hidden px-2">
          Messages
        </Font>
        <View className="flex-1 flex lg:hidden"></View>
        <Pressable
          className="p-3 rounded-full active:bg-app-c2 active:bg-opacity-10"
          onPress={() => navigation.push("notifications")}
        >
          <RemixIcon
            name="notification-4-line"
            className="flex lg:hidden"
            color={colors.app.a}
            size="22"
          ></RemixIcon>
        </Pressable>
      </View>
      {Platform.OS === "web" && isLargeScreen && (
        <View className="w-full h-16 bg-app-c border-b border-app-c flex-row justify-center items-center  flex -mr-px ">
          <Font className="text-app-d1">Conversations</Font>
        </View>
      )}
      <ScrollView
        className="p-4 lg:p-0 rounded-t-2xl lg:rounded-none  bg-gray-100"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          className={`flex-1 w-full h-full divide-y divide-gray-200 bg-white rounded-lg border border-gray-200 md:rounded-none ${
            Platform.OS === "web" ? "" : "p-1"
          }`}
        >
          {isChannelsLoading && !isRefreshing ? (
            <View className="absolute top-0 left-0 bottom-0 right-0 flex flex-col flex-1 justify-center items-center">
              <ActivityIndicator color={colors.app.e} size={"large"} />
            </View>
          ) : (
            <FlatList
              contentContainerStyle={{
                padding: Platform.OS === "web" ? "1px" : 0,
              }}
              refreshing={isRefreshing}
              onRefresh={async () => {
                setIsRefreshing(true);
                await onRefresh();
                setTimeout(() => setIsRefreshing(false), 3000);
              }}
              data={channels}
              renderItem={({ item, index }) => {
                const textColor =
                  currentChatData.currIndex === index
                    ? "text-app-a"
                    : "text-app-d";
                const isCurrentActiveChat = currentChatData.currIndex === index;
                return (
                  <Pressable
                    className={`flex-row p-4 rounded-lg border-app-c ${
                      Platform.OS === "web" ? "border-b shadow-sm" : "border"
                    } ${isCurrentActiveChat ? "bg-app-d" : ""}`}
                    onPress={() => {
                      if (!isCurrentActiveChat) chatPressHandler(item, index);
                      navigation.closeDrawer();
                    }}
                    style={{ marginTop: index == 0 ? 0 : 8 }}
                  >
                    <View className="rounded-full w-10 h-10 overflow-hidden shadow-xl">
                      <Image
                        source={
                          item?.customer?.profile_image_url
                            ? {
                                uri: mediaURI + item.customer.profile_image_url,
                              }
                            : DefaultImages.DP_PLACEHOLDER
                        }
                        className="w-full h-full"
                      ></Image>
                    </View>
                    <View className="flex-1 ml-4">
                      <View className="flex-row">
                        <Font
                          className={`flex-1 text-[15px] ${textColor}`}
                          weight={600}
                        >
                          {parseProfileName(
                            item?.customer?.first_name,
                            item?.customer?.last_name
                          )}
                        </Font>
                        <Font className={`${textColor} text-xs`}>
                          {getTime(item?.created_at, "hh:MM A") || "--:-- --"}
                        </Font>
                      </View>
                      <View className="flex-row">
                        <Font
                          className={`flex-1 ${textColor} pt-[2px] text-xs ${
                            item.unread && !item.typingUserInfo
                              ? "font-bold"
                              : ""
                          } ${
                            item.typingUserInfo
                              ? isCurrentActiveChat
                                ? "text-app-a"
                                : "text-app-e1"
                              : ""
                          }`}
                          weight={item.unread ? 800 : 500}
                          numberOfLines={1}
                        >
                          {item.typingUserInfo
                            ? `${
                                (item.typingUserInfo.first_name || "") +
                                  (item.typingUserInfo.last_name
                                    ? " " + item.typingUserInfo.last_name
                                    : "") || item.typingUserInfo.email
                              } typing...`
                            : item?.last_message?.message}
                        </Font>
                        {item.unread ? (
                          <View className="px-1 py-px rounded-full bg-app-e items-center">
                            <Font className="text-xs overflow-visible min-w-[10px] text-center text-app-a">
                              {item.unread <= 99 ? item.unread : 99 + "+"}
                            </Font>
                          </View>
                        ) : (
                          <></>
                        )}
                      </View>
                    </View>
                  </Pressable>
                );
              }}
              ListEmptyComponent={() => (
                <View className="w-full flex-1 justify-center items-center pt-4 pb-8">
                  <Font>No Messages Found</Font>
                </View>
              )}
            />
          )}
        </View>
      </ScrollView>
    </View>
  );
};

const ChatBubble = ({
  isOwnMessageBubble,
  time,
  message,
  isSending,
  onDeletePress,
  isDeleting,
}) => {
  // const [showTime, setShowTime] = React.useState(isSending);
  const [showOptions, setShowOptions] = React.useState(false);

  return (
    <View
      className={`w-max ${
        isOwnMessageBubble ? "self-end" : "self-start"
      } p-2 min-w-min max-w-[80%] overflow-visible`}
    >
      <View className="flex flex-row">
        <View className={`${Platform.OS === "web" ? "flex-1" : ""}`}>
          <Pressable
            className={`p-4 rounded-lg ${
              isOwnMessageBubble
                ? "rounded-br-none bg-app-d"
                : "rounded-bl-none bg-gray-100 border border-app-c"
            }`}
            // onPress={() => setShowTime(!showTime)}
          >
            <Font
              className={`${
                isOwnMessageBubble ? "text-app-a" : "text-app-d"
              } whitespace-normal`}
            >
              {message}
            </Font>
          </Pressable>
          {/* {(showTime || isDeleting) && ( */}
          <Font
            className={`text-xs font-thin text-app-d ${
              isOwnMessageBubble ? "self-end" : "self-start"
            }`}
          >
            {isOwnMessageBubble
              ? isSending
                ? "Sending..."
                : isDeleting
                ? "Deleting..."
                : time
              : time}
          </Font>
        </View>
        {isOwnMessageBubble && !isSending && (
          <Pressable
            onPress={() => {
              setShowOptions((showOptions) => !showOptions);
            }}
            // className="w-full"
          >
            <RemixIcon
              name="ri-more-2-fill"
              className="self-start cursor-pointer"
              size={20}
              color={colors.app.d2}
            />
            {showOptions && (
              <View className="w-max bg-white border border-app-d3 absolute rounded-lg rounded-tr-none right-8 top-0 shadow-lg max-h-min h-full min-h-max">
                <Pressable
                  onPress={() => {
                    onDeletePress();
                    setShowOptions(false);
                  }}
                  className="w-max h-max rounded-lg rounded-tr-none border-b border-app-c"
                >
                  <View className="px-4 py-4 w-max rounded-lg">
                    <Font className="text-xs w-full h-max text-app-e leading-3">
                      Delete&nbsp;Message
                    </Font>
                  </View>
                </Pressable>
              </View>
            )}
          </Pressable>
        )}
      </View>
    </View>
  );
};

export const MessagesContent = ({
  navigation,
  isLargeScreen,
  messages,
  onSendMessage,
  currentChatData,
  setCurrentChatData,
  currentChannelMessages,
  setCurrentChannelMessages,
  setShowResponseModal,
  currentChannelMessagesPagination,
  setCurrentChannelMessagesPagination,
  dispatchMessageEvents,
  onDeleteMessage,
  user,
  messageText,
  setMessageText,
  isTyping,
  setIsTyping,
  // scrollToEnd,
  // setScrollToEnd,
}) => {
  // const { messages, setMessages, messagesPagination, setMessagesPagination } =
  //   useMessagesContext();
  const [scrollRef, setScrollRef] = React.useState();
  // const [isEditingMessage, setIsEditingMessage] = React.useState("");
  const [vMIsLoading, setVMIsLoading] = React.useState();
  const [scrollControlData, setScrollControlData] = React.useState({
    enabled: true,
    lastYHeight: 0,
    isFirstScroll: true,
  });

  React.useEffect(() => {
    return () => {
      setCurrentChatData({
        data: null,
        currIndex: undefined,
        prevIndex: undefined,
        self_user_id: null,
      });
      setCurrentChannelMessages({ data: null });
      setCurrentChannelMessagesPagination({
        from: null,
        to: null,
        total: null,
      });
    };
  }, []);

  React.useEffect(() => {
    let t;
    if (isTyping) {
      t = setTimeout(() => {
        setIsTyping(false);
      }, 1000);
    }
    return () => {
      clearTimeout(t);
    };
  }, [isTyping]);

  const getVendorMessages = async (channel_id, offset, limit, load_more) => {
    if (load_more) {
      limit = limit || load_records;
      offset = currentChannelMessagesPagination.to;
    }
    setVMIsLoading(true);
    let data, total_count;
    try {
      const token = await isSignedIn();
      const res = await axiosInstance.get(
        apiURIV2 + "/chat/channels/" + channel_id + "/messages",
        {
          params: { offset, limit: limit || load_records },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        total_count = res.data.total_count;
        data = res.data.data;
      }
    } catch (e) {
      console.log("vendors e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Getting Vendor Messages"
        ),
      });
    } finally {
      const pagObj = {
        from: (offset || -1) + 1 || initial_from,
        to:
          (offset || -1) + 1
            ? offset + (data?.length || load_records)
            : data?.length || load_records,
        total: total_count,
      };
      if (data) {
        setCurrentChannelMessagesPagination(pagObj);
        setCurrentChannelMessages({
          data: (data || []).reverse(),
          unshift: load_more ? true : false,
        });
      }
      setVMIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (!currentChannelMessages && currentChatData?.id && !vMIsLoading) {
      getVendorMessages(currentChatData.id, undefined, 20);
    }
  }, [currentChannelMessages, currentChatData]);

  React.useEffect(() => {
    if (currentChannelMessages && scrollRef) scrollRef.scrollToEnd();
  }, [scrollRef]);

  let prevDate = { date: null, id: null };
  const getChatsFullDate = (dateToday, id) => {
    if (!dateToday) return <></>;
    let currDate = getFullDateWithWeekday(dateToday);
    if (prevDate.date !== currDate) {
      prevDate.date = currDate;
      prevDate.id = id;
      return (
        <View className="w-full flex-1 justify-center items-center py-4 h-10">
          <Font className="text-app-d bg-gray-100 border border-app-c px-2 py-1 rounded h-8">
            {currDate}
          </Font>
        </View>
      );
    } else if (prevDate.date === currDate && prevDate.id === id) {
      return (
        <View className="w-full flex-1 justify-center items-center py-4 h-10">
          <Font className="text-app-d bg-gray-200 border border-app-c px-2 py-1 rounded h-8">
            {currDate}
          </Font>
        </View>
      );
    } else return <></>;
  };

  const sendMessageHandler = (message) => {
    setIsTyping(false);
    if (message) {
      setMessageText("");
      onSendMessage({ message, channel_id: currentChatData.id });
    }
  };

  // React.useEffect(() => {
  //   if (scrollToEnd && scrollControlData.enabled) {
  //     setScrollToEnd(false);
  //     scrollRef.scrollToEnd({ animated: false });
  //   }
  // }, [scrollToEnd]);

  const onScrollHandler = (event) => {
    const contentOffsetY = event.nativeEvent.contentOffset.y;
    if (contentOffsetY <= 0 && !vMIsLoading) {
      // user scrolled up to top of chat history
      getVendorMessages(currentChatData.id, undefined, load_records, true);
      setScrollControlData({
        enabled: false,
        isFirstScroll: false,
        lastYHeight: event.nativeEvent.contentSize.height,
        // + event.nativeEvent.layoutMeasurement.height / 10,
      });
    } else if (
      !scrollControlData.enabled &&
      contentOffsetY >=
        (event.nativeEvent.contentSize.height -
          event.nativeEvent.layoutMeasurement.height) *
          0.8
    ) {
      setScrollControlData({
        enabled: true,
        isFirstScroll: false,
        lastYHeight: 0,
      });
    }
  };

  return currentChatData?.id ? (
    <View className="flex-1 rounded-lg">
      <View className="w-full h-16 bg-app-e items-center flex-row px-1  flex ">
        <Pressable
          className="p-3 rounded-full active:bg-app-c2 active:bg-opacity-10 flex lg:hidden"
          onPress={() => {
            if (isTyping) setIsTyping(false);
            setCurrentChatData({
              data: null,
              currIndex: undefined,
              prevIndex: undefined,
              self_user_id: null,
            });
            setCurrentChannelMessages({ data: null });
            setCurrentChannelMessagesPagination({
              from: null,
              to: null,
              total: null,
            });
            navigation.openDrawer();
          }}
        >
          <RemixIcon
            name="arrow-left-line"
            color={colors.app.a}
            size="22"
          ></RemixIcon>
        </Pressable>
        <View className="flex-row p-1 lg:p-4">
          <View className="rounded-full w-10 h-10 overflow-hidden shadow-md">
            <Image
              source={
                currentChatData.customer?.profile_image_url
                  ? {
                      uri:
                        mediaURI + currentChatData.customer.profile_image_url,
                    }
                  : DefaultImages.DP_PLACEHOLDER
              }
              className="w-full h-full"
            ></Image>
          </View>
          <View className="px-4 justify-center">
            <View className="flex-col">
              <Font className="text-[15px] text-app-a" weight={600}>
                {parseProfileName(
                  currentChatData.customer?.first_name,
                  currentChatData.customer?.last_name
                )}
              </Font>
              <Font className="text-app-b text-xs">
                {currentChatData.typingUserInfo
                  ? `${
                      (currentChatData.typingUserInfo?.first_name || "") +
                        (currentChatData.typingUserInfo?.last_name
                          ? " " + currentChatData.typingUserInfo.last_name
                          : "") || currentChatData.typingUserInfo?.email
                    } typing...`
                  : currentChatData.isUserOnline
                  ? "Online"
                  : ""}
              </Font>
            </View>
          </View>
        </View>
        <View className="flex-1 flex lg:hidden"></View>
        <Pressable
          className="p-3 rounded-full active:bg-app-c2 active:bg-opacity-10 lg:hidden"
          onPress={() => navigation.push("notifications")}
        >
          <RemixIcon
            name="notification-4-line"
            color={colors.app.a}
            size="22"
          ></RemixIcon>
        </Pressable>
        {/* <Pressable
          className="p-3 rounded-full active:bg-app-c2 active:bg-opacity-10 flex lg:hidden"
          onPress={() => navigation.openDrawer()}
        >
          <RemixIcon
            name="arrow-left-line"
            color={colors.app.a}
            size="22"
          ></RemixIcon>
        </Pressable> */}
        {/* <Font className="text-app-a lg:text-app-d font-semibold text-[15px] flex lg:hidden px-2">
          Messages
        </Font> */}
        {/* <View className="flex-1 flex lg:hidden"></View>
        <Pressable
          className="p-3 rounded-full active:bg-app-c2 active:bg-opacity-10"
          onPress={() => navigation.push("notifications")}
        >
          <RemixIcon
            name="notification-4-line"
            className="flex lg:hidden"
            color={colors.app.a}
            size="22"
          ></RemixIcon>
        </Pressable> */}
      </View>
      <ScrollView
        ref={(ref) => setScrollRef(ref)}
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        className="bg-app-a"
        // snapToEnd={true}
        onScroll={onScrollHandler}
        onContentSizeChange={(w, h) => {
          if (scrollControlData.enabled) {
            ((isFirstScroll) =>
              scrollRef.scrollToEnd({
                animated: isFirstScroll ? true : false,
              }))(!scrollControlData.isFirstScroll);
            if (scrollControlData.isFirstScroll) {
              setScrollControlData({
                enabled: true,
                isFirstScroll: false,
                lastYHeight: 0,
              });
            }
          } else if (scrollControlData.lastYHeight) {
            scrollRef.scrollTo({
              y: h - scrollControlData.lastYHeight,
              x: 0,
              animated: false,
            });
            setScrollControlData({
              enabled: false,
              isFirstScroll: false,
              lastYHeight: 0,
            });
          }
        }}
        scrollEventThrottle={1000} //ms
      >
        {Platform.OS === "web"
          ? vMIsLoading && (
              <ActivityIndicator
                animating={true}
                size={Platform.OS === "web" ? "small" : "large"}
                color={colors.app.e}
                className="mb-4 shadow-md bg-app-a/50 rounded-full p-2 z-50 top-2 absolute place-self-start"
              />
            )
          : null}
        <View className={`w-full h-full justify-end`}>
          <FlatList
            // key={"msgs-length-" + messages?.length}
            contentContainerStyle={{
              justifyContent: "flex-end",
              alignItems: "stretch",
              minHeight: "100%",
              maxHeight: "100%",
              paddingLeft: 20,
            }}
            refreshControl={
              <RefreshControl
                refreshing={vMIsLoading}
                onRefresh={() => getVendorMessages()}
              />
            }
            data={currentChannelMessages}
            extraData={currentChannelMessages}
            // viewabilityConfig={{
            //   itemVisiblePercentThreshold: 80,
            //   minimumViewTime: 1000,
            // }}
            renderItem={({ item, index }) => {
              const time = getTime(parseInt(item.timestamp));
              const date = getChatsFullDate(parseInt(item.timestamp), item.id);
              return (
                <>
                  {date}
                  <ChatBubble
                    isOwnMessageBubble={item.sender_id === user.id}
                    message={item.message}
                    isSending={!item.timestamp}
                    time={time}
                    onDeletePress={() => {
                      onDeleteMessage({
                        channel_id: currentChatData.id,
                        message_id: item.id,
                        messageIndex: index,
                        data: item,
                      });
                    }}
                    isDeleting={item.isDeleting}
                  />
                </>
              );
            }}
          />
        </View>
      </ScrollView>
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        keyboardVerticalOffset={50}
      >
        <View className="h-18 w-full p-2 flex-row items-center border-t lg:bg-app-d/95 border-gray-200">
          <View className="flex-1 ml-1 mr-2">
            {/* <Input type="text" placeholder="Type your message"></Input> */}
            <View
              className={`rounded-lg border border-gray-200 overflow-hidden ${
                messageText?.length > 100 ? "h-20" : "h-10"
              } bg-app-a`}
            >
              <TextInput
                key={currentChatData.id + "-text"}
                placeholder="Type your message"
                className={`w-full ${
                  Platform.OS === "web" ? "flex-1 py-2.5" : "h-full"
                } px-3 min-w-0 lg:bg-app-d/95 lg:text-app-d bg-app-d/10`}
                style={
                  Platform.OS === "web" && {
                    outlineColor: "transparent",
                    outlineWidth: 0,
                  }
                }
                multiline={true}
                textAlignVertical="center"
                value={messageText}
                onChangeText={(e) => {
                  setMessageText(e);
                  const isEmpty = ["", undefined, null].includes(e);
                  if (!isEmpty && !isTyping) {
                    setIsTyping(true);
                  } else if (isEmpty && isTyping) {
                    setIsTyping(false);
                  }
                }}
                onKeyPress={(e) => {
                  if (!e.shiftKey && ["Enter"].includes(e.code)) {
                    e.preventDefault();
                    sendMessageHandler(messageText);
                  }
                }}
              />
            </View>
          </View>
          {/* {!!isEditingMessage && (
          <Pressable
            className={`w-12 self-center h-10 mr-1 items-center justify-center rounded-lg ${
              Platform.OS === "web" ? "active:bg-app-c2" : "active:bg-app-d3"
            }`}
            onPress={() => {
              setMessageText("");
              setIsEditingMessage();
            }}
          >
            <RemixIcon
              name="ri-close-circle-fill"
              color={Platform.OS === "web" ? colors.app.a : null}
            ></RemixIcon>
          </Pressable>
        )} */}
          <Pressable
            className={`w-12 self-center h-10 items-center justify-center rounded-lg ${
              Platform.OS === "web" ? "active:bg-app-c2" : "active:bg-app-d3"
            }`}
            onPress={() => sendMessageHandler(messageText)}
          >
            <RemixIcon
              name="send-plane-2-line"
              color={
                Platform.OS === "web"
                  ? isLargeScreen
                    ? colors.app.a
                    : colors.app.d
                  : null
              }
            ></RemixIcon>
          </Pressable>
        </View>
      </KeyboardAvoidingView>
    </View>
  ) : (
    <View
      className={`flex-1 ${
        isLargeScreen ? "rounded-r-lg" : ""
      } bg-app-d justify-center items-center`}
    >
      <View className="rounded-full w-24 h-24 overflow-hidden mb-2 -mt-8">
        <Image
          source={require("../../../assets/smbex-logoa.png")}
          className="w-full h-full"
        ></Image>
      </View>
      <View className="flex-row rounded bg-app-a/50 px-4 py-1 shadow-sm">
        <Font className="text-2xl font- text-app-d -mt-px" weight={600}>
          SMB
        </Font>
        <Font className="text-2xl font- text-app-e -mt-px" weight={600}>
          Ex
        </Font>
        <Font className="text-2xl text-app-a -mt-px"> Support Chat</Font>
      </View>
      <Font className="text-lg text-app-c">
        Welcome, {parseProfileName(user.first_name, user.last_name)}
      </Font>
    </View>
  );
};
